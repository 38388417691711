/* You can add global styles to this file, and also import other style files */

/*================================================
Default CSS
=================================================*/
$font_family: 'Nunito', sans-serif;
$template_color: #015493;
$template_color2: #FF8A73;
$white_color: #ffffff;
$black_color: #000000;
$paragraph_color: #818992;

body {
    font-family: $font_family;
    padding: 0;
    margin: 0;
}
a {
    color: $black_color;
    text-decoration: none;
    transition: .4s;
    display: inline-block;
    outline: 0;

    &:hover, &:focus {
        text-decoration: none;
        color: $template_color2;
    }
}
img {
    max-width: 100%;
}
.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    }
}
.bg-gray {
    background-color: #edf5ff;
}
p {
    font-size: 16px;
    color: $paragraph_color;
    line-height: 1.6;

    &:last-child {
        margin-bottom: 0;
    }
}
button {
    outline: 0 !important;
}
/* btn btn-primary */
.btn-primary {
    color: $white_color;
    background-color: $template_color2;

    &:hover, &:focus, &:active {
        color: $white_color;
        background: $template_color;
        transform: translateY(-5px);
    }
    &.focus, &:focus {
        box-shadow: unset;
    }
    &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
        box-shadow: unset;
    }
    &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
        color: $white_color;
        background: $template_color;
        transform: translateY(-5px);
    }
    &.disabled, &:disabled {
        color: $white_color;
        background-color: $template_color2;
        border-color: $template_color2;
        opacity: 1;

        &:hover, &:focus {
            background: $template_color;
        }
    }
}
.btn {
    border: none;
    padding: 13px 31px;
    border-radius: 5px;
    text-transform: uppercase;
    position: relative;
    box-shadow: 0 13px 27px 0 rgba(163,48,53,.25);
    z-index: 1;
    transition: .9s;
    font: {
        weight: 700;
        size: 16px;
    }
    &::after, &::before {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: 0;
        transition: .4s;
        background: $template_color;
        z-index: -1;
        border-radius: 5px;
    }
    &::after {
        left: 0;
    }
    &::before {
        right: 0;
    }
    &:hover {
        &::after, &::before {
            width: 50%;
        }
    }
}

/* section title */
.section-title {
    text-align: center;
    max-width: 720px;
    margin: {
        bottom: 70px;
        top: -6px;
        left: auto;
        right: auto;
    }
    h2 {
        margin-bottom: 0;
        font: {
            size: 35px;
            weight: 700;
        }
    }
    p {
        max-width: 650px;
        margin: 0 auto;
    }
    .bar {
        height: 4px;
        width: 85px;
        background: $template_color;
        margin: 20px auto 25px;
        position: relative;
        border-radius: 5px;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 5px;
            background: $white_color;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-name: MOVE-BG;
        }
    }
}
@-webkit-keyframes MOVE-BG {
    from {
        -webkit-transform: translateX(0);
    }
    to {
        -webkit-transform: translateX(85px);
    }
}
@keyframes MOVE-BG {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(85px);
    }
}
/* -------- Portfolio Isotop Gallery ---------- */
.gallery-isotope {
    /*gutter*/
    /* by default 4 grids */
    /*portfolio 2 grids*/
    /*portfolio 2 grids*/
    /*portfolio 3 grids*/
    /*portfolio 4 grids*/
    /*portfolio 5 grids*/
    /*portfolio 6 grids*/
    /*portfolio 7 grids*/
    /*portfolio 8 grids*/
    /*portfolio 9 grids*/
    /*portfolio 10 grids*/
}
.demo-gallery {
    position: relative;
    padding: 10px;
    overflow: hidden;
}
.gallery-isotope.gutter .gallery-item {
    padding-right: 5px;
    padding-bottom: 5px;
}
.gallery-isotope.gutter-small .gallery-item {
    padding-right: 2px;
    padding-bottom: 2px;
}
.gallery-isotope.gutter-30 .gallery-item {
    padding-right: 30px;
    padding-bottom: 30px;
}
.gallery-isotope.gutter-40 .gallery-item {
    padding-right: 40px;
    padding-bottom: 40px;
}
.gallery-isotope.gutter-50 .gallery-item {
    padding-right: 50px;
    padding-bottom: 50px;
}
.gallery-isotope.gutter-60 .gallery-item {
    padding-right: 60px;
    padding-bottom: 60px;
}
.gallery-isotope .gallery-item {
    width: 25%;
}
.gallery-isotope .gallery-item.wide {
    width: 50%;
}
.gallery-isotope.grid-1 .gallery-item {
    width: 100%;
}
.gallery-isotope.grid-1 .gallery-item.wide {
    width: 100%;
}
.gallery-isotope.grid-2 .gallery-item {
    width: 50%;
}
.gallery-isotope.grid-2 .gallery-item.wide {
    width: 100%;
}
.gallery-isotope.grid-3 .gallery-item {
    width: 33.333%;
}
.gallery-isotope.grid-3 .gallery-item.wide {
    width: 66.6667%;
}
.gallery-isotope.grid-4 .gallery-item {
    width: 25%;
}
.gallery-isotope.grid-4 .gallery-item.wide {
    width: 50%;
}
.gallery-isotope.grid-5 .gallery-item {
    width: 20%;
}
.gallery-isotope.grid-5 .gallery-item.wide {
    width: 40%;
}
.gallery-isotope.grid-6 .gallery-item {
    width: 16.666666667%;
}
.gallery-isotope.grid-6 .gallery-item.wide {
    width: 33.333%;
}
.gallery-isotope.grid-7 .gallery-item {
    width: 14.2857143%;
}
.gallery-isotope.grid-7 .gallery-item.wide {
    width: 28.5714286%;
}
.gallery-isotope.grid-8 .gallery-item {
    width: 12.5%;
}
.gallery-isotope.grid-8 .gallery-item.wide {
    width: 25%;
}
.gallery-isotope.grid-9 .gallery-item {
    width: 11.11%;
}
.gallery-isotope.grid-9 .gallery-item.wide {
    width: 22.22%;
}
.gallery-isotope.grid-10 .gallery-item {
    width: 10%;
}
.gallery-isotope.grid-10 .gallery-item.wide {
    width: 20%;
}


/* form-control */
.form-group {
    margin-bottom: 15px;
}
.form-control {
    height: 50px;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    transition: .4s;
    color: $paragraph_color;
    background: $white_color;

    &::placeholder {
        color: $paragraph_color;
    }
    &:focus {
        color: $paragraph_color;
        background-color: $white_color;
        border-color: $template_color;
        outline: 0;
        box-shadow: unset;
    }
}

/*================================================
Preloader CSS
=================================================*/
.preloader-area {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999999999;
    background: $white_color;

    .lds-hourglass {
        display: inline-block;
        width: 80px;
        height: 80px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 50%;
        margin-top: -95px;

        &::after {
            content: " ";
            display: block;
            border-radius: 50%;
            width: 0;
            height: 0;
            margin: 6px;
            box-sizing: border-box;
            border: 36px solid $template_color;
            border-color: $template_color transparent $template_color2 transparent;
            animation: lds-hourglass 2.9s infinite;
        }
    }
}
@keyframes lds-hourglass {
    0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
        transform: rotate(1800deg);
    }
}

/*================================================
Navbar CSS
=================================================*/
.crake-mobile-nav {
    display: none;
}
.crake-nav {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 15px;
    width: 100%;
    z-index: 666;
    height: auto;

    nav {
        ul {
            list-style-type: none;
        }
        .navbar-nav {
            li {
                position: relative;
                padding: 15px 0 !important;

                a {
                    color: $white_color !important;
                    padding: 0 0 !important;
                    margin: 0 17px;
                    position: relative;
                    transition: .5s;
                    font: {
                        weight: 600;
                        size: 17px;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 9px;
                        width: 6px;
                        height: 6px;
                        background: $white_color;
                        opacity: 0;
                        border-radius: 50%;
                        visibility: hidden;
                        transition: .4s;
                    }
                    &:hover {
                        &::before {
                            opacity: 1;
                            visibility: visible;
                            left: -12px;
                        }
                    }
                    &.active {
                        &::before {
                            opacity: 1;
                            visibility: visible;
                            left: -12px;
                        }
                    }
                }
                &:hover a {
                    &::before {
                        opacity: 1;
                        visibility: visible;
                        left: -12px;
                    }
                }
                .dropdown-menu {
                    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
                    background: $white_color;
                    position: absolute;
                    top: 80px;
                    left: 0;
                    border: none;
                    width: 250px;
                    z-index: 99;
                    padding: 20px 0;
                    opacity: 0;
                    border-radius: 5px;
                    display: block;
                    visibility: hidden;
                    transition: all 0.3s ease-in-out;

                    li {
                        position: relative;
                        padding: 0 !important;

                        a {
                            padding: 8px 15px !important;
                            margin: 0;
                            display: block;
                            color: #716c80 !important;
                            font: {
                                size: 16px;
                                weight: 600;
                            }
                            &::before {
                                left: 0;
                                background: $template_color;
                                opacity: 0;
                                visibility: hidden;
                                top: 50%;
                                margin-top: -3px;
                            }
                            &:hover {
                                color: $template_color !important;
                                padding-left: 25px !important;

                                &::before {
                                    left: 13px;
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                            &.active {
                                color: $template_color !important;
                                padding-left: 25px !important;

                                &::before {
                                    left: 13px;
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                        .dropdown-menu {
                            position: absolute;
                            left: 220px;
                            top: 0 !important;
                            opacity: 0 !important;
                            visibility: hidden !important;

                            li {
                                a {
                                    padding-left: 15px !important;
                                    color: #716c80 !important;

                                    &::before {
                                        opacity: 0;
                                        left: 0;
                                        visibility: hidden;
                                    }
                                    &:hover {
                                        color: $template_color !important;
                                        padding-left: 25px !important;

                                        &::before {
                                            left: 13px;
                                            opacity: 1;
                                            visibility: visible;
                                        }
                                    }
                                    &.active {
                                        color: $template_color !important;
                                        padding-left: 25px !important;

                                        &::before {
                                            left: 13px;
                                            opacity: 1;
                                            visibility: visible;
                                        }
                                    }
                                }
                            }
                        }
                        &:hover {
                            .dropdown-menu {
                                opacity: 1 !important;
                                visibility: visible !important;
                                top: -20px !important;
                            }
                            a {
                                padding-left: 25px !important;
                                color: $template_color !important;

                                &::before {
                                    left: 13px;
                                    visibility: visible;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
                &:hover {
                    .dropdown-menu {
                        opacity: 1 !important;
                        visibility: visible !important;
                        top: 100%;
                    }
                }
            }
        }
        .others-option {
            .navbar-nav {
                align-items: center;

                .cart-wrapper {
                    position: relative;
                    padding: 0 !important;

                    a {
                        margin: 0 0 0 10px !important;

                        .cart-icon {
                            line-height: 52px;
                            font-size: 25px;
                        }
                        span {
                            position: relative;
                            width: 16px;
                            height: 16px;
                            font-size: 12px;
                            background: $black_color;
                            display: inline-block;
                            text-align: center;
                            line-height: 16px;
                            border-radius: 50%;
                            top: -15px;
                            color: $white_color;
                            padding-right: 1px;
                            right: 10px;
                        }
                    }
                }
                li {
                    padding: 0 !important;

                    a {
                        margin: 0 22px 0 10px !important;

                        &::before {
                            display: none;
                        }
                    }
                    .side-menu {
                        cursor: pointer;
                        width: 32px;

                        .bar-1 {
                            position: relative;
                            width: 8px;
                            height: 8px;
                            background: $white_color;
                            display: block;
                            border-radius: 50%;
                            margin-bottom: 2px;
                            transition: all 0.4s ease-in-out;

                            &::before, &::after {
                                content: '';
                                position: absolute;
                                right: -12px;
                                top: 0;
                                width: 8px;
                                height: 8px;
                                background: $white_color;
                                border-radius: 50%;
                                transition: all 0.4s ease-in-out;
                            }
                            &::after {
                                right: -24px;
                            }
                        }
                        .bar-2 {
                            position: relative;
                            width: 8px;
                            height: 8px;
                            margin-bottom: 2px;
                            background: $white_color;
                            display: block;
                            border-radius: 50%;
                            transition: all 0.4s ease-in-out;

                            &::before, &::after {
                                content: '';
                                position: absolute;
                                right: -12px;
                                top: 0;
                                width: 8px;
                                height: 8px;
                                background: $white_color;
                                border-radius: 50%;
                                transition: all 0.4s ease-in-out;
                            }
                            &::after {
                                right: -24px;
                            }
                        }
                        .bar-3 {
                            position: relative;
                            width: 8px;
                            height: 8px;
                            background: $white_color;
                            margin-bottom: 2px;
                            display: block;
                            border-radius: 50%;
                            transition: all 0.4s ease-in-out;

                            &::before, &::after {
                                content: '';
                                position: absolute;
                                right: -12px;
                                top: 0;
                                width: 8px;
                                height: 8px;
                                background: $white_color;
                                border-radius: 50%;
                                transition: all 0.6s ease-in-out;
                            }
                            &::after {
                                right: -24px;
                                transition: all 0.8s ease-in-out;
                            }
                        }
                        &:hover, &:focus {
                            .bar-2 {
                                &::after {
                                    opacity: 0;
                                    visibility: hidden;
                                    transform: translateX(-100%);
                                }
                            }
                            .bar-3 {
                                &::after, &::before {
                                    opacity: 0;
                                    visibility: hidden;
                                    transform: translateX(-100%);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.marketing-navbar {
        nav {
            .navbar-nav {
                li {
                    a {
                        color: #716c80 !important;

                        &::before {
                            background: $template_color;
                        }
                        &:hover, &:focus, &.active {
                            color: $template_color !important;
                        }
                    }
                    .side-menu {
                        .bar-1 {
                            background: #716c80;

                            &::before, &::after {
                                background: #716c80;
                            }
                        }
                        .bar-2 {
                            background: #716c80;

                            &::before, &::after {
                                background: #716c80;
                            }
                        }
                        .bar-3 {
                            background: #716c80;

                            &::before, &::after {
                                background: #716c80;
                            }
                        }
                    }
                }
            }
        }
        &.is-sticky {
            background: $white_color !important;
        }
    }
    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0;
        z-index: 999;
        box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
        background-color: $template_color !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    }
}
.header-search {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    transition: all 0.5s ease-in-out;
    transform: translate(0px, -100%) scale(0, 0);
    opacity: 0;
    visibility: hidden;
    z-index: 9999;

    .header-search-form {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        max-width: 950px;
        margin: 0 auto;

        input {
            &[type="search"] {
                width: 100%;
                color: #222D39;
                font-size: 20px;
                text-align: left;
                margin: 0px auto;
                padding: 25px 10px 25px 30px;
                outline: none;
                background: transparent;
                transition: .4s;
                border: {
                    bottom: 1px solid $template_color;
                    top: 1px solid $template_color;
                    left: none;
                    right: none;
                }
                &:focus {
                    border-color: $template_color2;
                }
            }
        }
        .btn {
            position: absolute;
            right: 0;
            bottom: 0;
            height: 100%;
            padding: 0 50px;
        }
    }
    .close {
        position: absolute;
        color: $template_color2;
        background-color: transparent;
        border: none;
        opacity: 1 !important;
        visibility: visible;
        padding: 0;
        border-radius: 50%;
        cursor: pointer;
        width: 35px;
        height: 35px;
        border: 1px solid $template_color2;
        transition: .4s;
        right: 29%;
        margin: -100px auto 0;
        top: 50%;
        font: {
            size: 25px;
            weight: 300;
        }
        &:hover, &:focus {
            background-color: $template_color !important;
            color: $white_color !important;
            border-color: $template_color !important;
            opacity: 1 !important;
        }
    }
    &.open {
        transform: translate(0px, 0px) scale(1, 1);
        opacity: 1;
        visibility: visible;
    }
}
.sidebar-modal {
    position: relative;

    .navbar-nav {
        li {
            a {
                padding: 10px 0px 10px 15px;
            }
        }
    }
    .modal.right {
        .modal-dialog {
            position: fixed;
            margin: auto;
            width: 480px;
            height: 100%;
            -webkit-transform: translate3d(0%, 0, 0);
            -ms-transform: translate3d(0%, 0, 0);
            -o-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0);
        }
        &.fade.show {
            .modal-dialog {
                right: 0;
                transition: all 0.3s ease-in-out;
            }
        }
        &.fade {
            .modal-dialog {
                right: -320px;
                transition: all 0.3s ease-in-out;
            }
        }
        .modal-content {
            height: 100%;
            overflow-y: auto;
            border-radius: 0;
            border: none;
        }
        .modal-body {
            padding: 30px;

            .sidebar-modal-widget {
                margin-bottom: 35px;

                .title {
                    position: relative;
                    z-index: 1;
                    margin-bottom: 35px;
                    font: {
                        size: 23px;
                        weight: 700;
                    }
                    &::before {
                        position: absolute;
                        left: 0;
                        bottom: -7px;
                        width: 70px;
                        height: 2px;
                        border-radius: 5px;
                        background: $template_color;
                        content: '';
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: -7px;
                        height: 2px;
                        width: 5px;
                        background: $white_color;
                        animation-duration: 2s;
                        animation-timing-function: linear;
                        animation-iteration-count: infinite;
                        animation-name: MOVE-BG;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
                ul {
                    padding: 0;
                    margin: 0;
                    list-style-type: none;

                    li {
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                    &.contact-info {
                        li {
                            position: relative;
                            font-weight: 700;
                            padding-left: 65px;
                            text-transform: uppercase;
                            margin-bottom: 17px;

                            i {
                                position: absolute;
                                left: 0;
                                top: 0;
                                background: #f4f4f4 none repeat scroll 0 0;
                                border: 1px solid #e7e7e7;
                                border-radius: 5px;
                                color: $template_color;
                                height: 50px;
                                text-align: center;
                                width: 50px;
                                line-height: 50px;
                                font-size: 25px;
                                transition: .4s;
                            }
                            span {
                                display: block;
                                font-weight: 400;
                                color: $paragraph_color;
                                margin-top: 5px;
                                text-transform: initial;
                            }
                            &:hover {
                                i {
                                    color: $white_color;
                                    border-color: $white_color;
                                    background: $template_color;
                                }
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                    &.social-list {
                        li {
                            display: inline-block;
                            margin-bottom: 0;
                            margin-right: 7px;

                            a {
                                width: 40px;
                                height: 40px;
                                line-height: 40px;
                                font-size: 15px;
                                text-align: center;
                                border-radius: 50%;
                                color: $paragraph_color;
                                border: 1px solid $paragraph_color;

                                &:hover, &:focus {
                                    color: $white_color;
                                    background: $template_color;
                                    border-color: $template_color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .modal-header {
        display: inline;
        padding: 0;
        border: none;

        .close {
            height: 60px;
            width: 60px;
            background: $template_color;
            font-size: 30px;
            color: $white_color;
            margin: 0px;
            padding: 0px;
            cursor: pointer;
            float: right;
            transition: all 0.3s ease-in-out;
            border: none;
            opacity: 1;

            &:hover {
                background: $template_color2;
                color: $white_color;
                transition: all 0.3s ease-in-out;
                opacity: 1;
            }
        }
        h2 {
            background: linear-gradient(142deg, #3ec9cf 0%, #2d136a 100%);
            padding: 30px 25px;
        }
    }
    button {
        &:focus {
            outline: 0px;
        }
    }
}

/*================================================
Main Banner CSS
=================================================*/
.main-banner {
    height: 840px;
    background: $template_color;
    position: relative;
    z-index: 1;

    .hero-content {
        h1 {
            margin: 0;
            color: $white_color;
            font: {
                size: 60px;
                weight: 700;
            }
        }
        p {
            color: #f2f2f2;
            font-size: 18px;
            margin: {
                top: 30px;
                bottom: 35px;
            }
        }
        .btn {
            &:hover {
                &::after, &::before {
                    background: $white_color;
                }
            }
        }
        .btn-primary {
            &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
                color: $template_color2;
                background: $white_color;
            }
            &:hover, &:focus {
                background: $white_color;
                color: $template_color2;
            }
        }
        .video-btn {
            margin-left: 15px;
            color: $white_color;
            text-transform: uppercase;
            font: {
                size: 15px;
                weight: 700;
            }
            i {
                font-size: 30px;
                position: relative;
                margin-left: 5px;
                top: 4px;
                transition: .4s;
            }
            &:hover {
                i {
                    color: $template_color2;
                }
            }
        }
        form {
            max-width: 540px;
            position: relative;

            .form-control {
                height: 70px;
                background: rgba(255, 255, 255,0.10);
                box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
                border: none;
                border-radius: 3px;
                color: $white_color;
                padding-left: 20px;
                background: $template_color;

                &::placeholder {
                    color: $white_color;
                }
            }
            .btn {
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
            }
        }
    }
    .signup-download {
        background: $white_color;
        padding: 30px 40px;
        border-radius: 3px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            background: $white_color;
            width: 96%;
            opacity: .62;
            height: 50%;
            bottom: -6px;
            left: 0;
            right: 0;
            margin: auto;
            border-radius: 3px;
            transition: .4s;
        }
        form {
            .form-group {
                margin-bottom: 20px;

                label {
                    display: block;
                    margin-bottom: 8px;
                    font: {
                        weight: 700;
                        size: 17px;
                    }
                }
            }
            .btn {
                display: block;
                width: 100%;
            }
        }
    }
    .app-image {
        img {
            position: absolute;
            left: 0;
            top: -40px;
            animation: movebounce 5s linear infinite;
            width: 400px;

            &:last-child {
                animation: movebounce 6s linear infinite;
                left: 270px;
                top: -20px;
            }
        }
    }
    .product-image {
        position: relative;

        img {
            animation: movebounce 6s linear infinite;
            width: 300px;

            &:nth-child(2) {
                position: absolute;
                right: -45px;
                bottom: -60px;
                animation: movebounce 5s linear infinite;
            }
        }
        .offer-badge {
            position: absolute;
            right: 90px;
            top: 30px;
            width: 100px;
            height: 100px;
            background: $template_color2;
            color: $white_color;
            border-radius: 50%;
            text-align: center;
            padding-top: 25px;
            animation: ripple-white 1s linear infinite;
            font: {
                size: 18px;
                weight: 700;
            }
            span {
                display: block;
            }
        }
    }
    .saas-image {
        position: relative;

        img {
            position: absolute;

            &:nth-child(1) {
                top: -200px;
                left: 0;
            }
            &:nth-child(2) {
                left: 55px;
                top: -100px;
            }
            &:nth-child(3) {
                left: 36%;
                z-index: 1;
                top: -170px;
            }
            &:nth-child(4) {
                right: 27%;
                top: 110px;
                z-index: 2;
            }
            &:nth-child(5) {
                right: 15%;
                top: 80px;
                z-index: 2;
            }
            &:nth-child(6) {
                right: 25%;
                top: -170px;
            }
            &:nth-child(7) {
                right: 47%;
                top: 96px;
                z-index: 2;
            }
            &:nth-child(8) {
                right: 22%;
                top: 45px;
                z-index: 1;
            }
            &:nth-child(9) {
                right: 75px;
                top: 20px;
            }
            &:nth-child(10) {
                left: 32%;
                top: -76px;
                z-index: 1;
            }
            &:nth-child(11) {
                right: 40%;
                top: -20px;
            }
            &:nth-child(12) {
                top: -292px;
                left: 45px;
            }
            &:last-child {
                display: none;
            }
        }
    }
    .startup-image {
        position: relative;

        img {
            position: absolute;
            width: unset !important;

            &:nth-child(1) {
                top: 45px;
                right: 23%;
            }
            &:nth-child(2) {
                left: 20px;
                top: -35px;
                right: 0;
                margin: 0 auto;
            }
            &:nth-child(3) {
                right: 0;
                top: 65px;
            }
            &:nth-child(4) {
                left: 85px;
                top: 0;
            }
            &:nth-child(5) {
                left: 110px;
                top: 132px;
            }
            &:nth-child(6) {
                right: 8%;
                top: 131px;
            }
            &:nth-child(7) {
                right: 20%;
                top: -30px;
            }
            &:nth-child(8) {
                right: 38%;
                top: 64px;
            }
            &:nth-child(9) {
                right: 0;
                top: -15px;
            }
            &:nth-child(10) {
                left: 0;
                top: 245px;
                right: 0;
                margin: 0 auto;
            }
            &:last-child {
                display: none;
            }
        }
    }
    .paymant-processing-image {
        position: relative;

        img {
            position: absolute;

            &:nth-child(1) {
                top: 135px;
                right: 0;
                left: 30px;
                margin: 0 auto;
            }
            &:nth-child(2) {
                right: 0;
                top: 25px;
                z-index: 1;
            }
            &:nth-child(3) {
                right: 0;
                top: 275px;
                left: 65%;
                margin: 0 auto;
                z-index: 1;
            }
            &:nth-child(4) {
                top: 170px;
                z-index: 1;
                left: 20px;
            }
            &:nth-child(5) {
                left: 115px;
                top: 240px;
                z-index: 1;
            }
            &:nth-child(6) {
                right: 0;
                top: -75px;
                left: 9px;
                margin: 0 auto;
            }
            &:nth-child(7) {
                right: 0;
                top: 23px;
            }
            &:nth-child(8) {
                left: 0;
                top: -45px;
                z-index: -1;
            }
            &:nth-child(9) {
                right: 21%;
                top: 16px;
            }
            &:last-child {
                display: none;
            }
        }
    }
    &.marketing-home {
        /* background: $white_color url("assets/img/bg-point.png") no-repeat fixed right -500px center/auto;*/

         .hero-content {
             h1 {
                 color: $black_color;
             }
             p {
                 color: $paragraph_color;
             }
             .video-btn {
                 color: #716c80;

                 &:hover, &:focus {
                     i {
                         color: $template_color;
                     }
                 }
             }
             .btn {
                 &:hover {
                     &::after, &::before {
                         background: $template_color;
                     }
                 }
             }
             .btn-primary {
                 &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
                     background: $template_color;
                     color: $white_color;
                 }
                 &:hover, &:focus {
                     background: $template_color;
                     color: $white_color;
                 }
             }
         }
         .marketing-image {
             position: relative;
             height: 100%;
             width: 100%;

             img {
                 position: absolute;

                 &:nth-child(1) {
                     right: 0;
                     top: -45px;
                 }
                 &:nth-child(2) {
                     left: 90px;
                     top: -30px;
                 }
                 &:nth-child(3) {
                     left: 60px;
                     top: 165px;
                 }
                 &:nth-child(4) {
                     left: 0;
                     right: -15px;
                     text-align: center;
                     margin: 0 auto;
                     top: 185px;
                 }
                 &:nth-child(5) {
                     bottom: -55px;
                     right: 0;
                 }
                 &:nth-child(6) {
                     bottom: -50px;
                     right: 140px;
                 }
                 &:nth-child(7) {
                     left: 198px;
                     bottom: -55px;
                 }
                 &:nth-child(8) {
                     left: 80px;
                     bottom: -40px;
                 }
                 &:nth-child(9) {
                     right: 65px;
                     bottom: 0;
                     animation: rotate3d 4s linear infinite;
                 }
                 &:nth-child(10) {
                     left: 50px;
                     top: 85px;
                 }
                 &:nth-child(11) {
                     right: 195px;
                     bottom: -55px;
                 }
                 &:nth-child(12) {
                     top: 100px;
                     right: -4px;
                     left: 0;
                     text-align: center;
                     margin: 0 auto;
                 }
                 &:nth-child(13) {
                     left: 62px;
                     right: 0;
                     margin: 0 auto;
                     text-align: center;
                     top: 20px;
                     animation: rotate3d 6s linear infinite;
                 }
                 &:last-child {
                     display: none;
                 }
             }
         }
     }
     &.product-home {
         .shape4 {
             left: auto;
             right: 20%;
             top: 25%;
         }
     }
     &.software-home {
         .bubble-animate {
             .circle {
                 background: $white_color;
             }
         }
     }
     &.payment-processing-home {
         .creative-shape {
             img {
                 width: 100%;
                 height: 55px;
             }
         }
     }
     &.book-home {
         background: {
             /*  image: url(assets/img/book-banner.jpg);*/
               position: center;
               size: cover;
               attachment: fixed;
               repeat: no-repeat;
           }
           &::before {
               content: '';
               position: absolute;
               left: 0;
               top: 0;
               width: 100%;
               height: 100%;
               background: $black_color;
               z-index: -1;
               opacity: .87;
           }
           .hero-content {
               span {
                   color: $white_color;
                   display: inline-block;
                   font-size: 18px;
                   margin-bottom: 10px;
                   font-weight: 700;
                   border-left: 3px solid $white_color;
                   padding-left: 10px;
               }
               em {
                   &.inlucdebooks {
                       display: block;
                       color: #f2f2f2;
                       margin-top: 20px;
                   }
               }
           }
           .book-image {
               height: 100%;
               width: 100%;
               position: relative;

               img {
                   position: absolute;
                   bottom: -215px;
                   right: 45px;
               }
           }
       }
   }
   .startup-home {
       .main-banner {
           background: {
               position: center;
               size: cover;
               repeat: no-repeat;
           }
           &::before {
               content: '';
               position: absolute;
               left: 0;
               top: 0;
               width: 100%;
               height: 100%;
               background: $black_color;
               z-index: -1;
               opacity: .95;
           }
       }
       .startup-bg1 {
           /*    background-image: url(assets/img/main-bg-1.jpg);*/
           }
           .startup-bg2 {
               /*    background-image: url(assets/img/main-bg-2.jpg);*/
               }
               .startup-bg3 {
                   /*  background-image: url(assets/img/main-bg-3.jpg);*/
                 }
                 &.owl-theme {
                     .owl-nav {
                         margin-top: 0;

                         [class*=owl-] {
                             position: absolute;
                             left: 10px;
                             top: 50%;
                             margin: 0;
                             width: 45px;
                             height: 45px;
                             background: rgba(255,255,255,0.1);
                             line-height: 42px;
                             font-size: 30px;
                             border-radius: 5px;
                             margin-top: -25px;
                             transition: .4s;
                             color: $white-color;

                             &.owl-next {
                                 left: auto;
                                 right: 10px;
                             }
                             &:hover {
                                 background: $template_color;
                             }
                         }
                     }
                 }
             }
             .app-home {
                 .shape4 {
                     left: auto !important;
                     right: 15%;
                 }
                 .col-lg-6 {
                     position: relative;
                 }
             }
             .creative-shape {
                 position: absolute;
                 bottom: -1px;
                 left: 0;
                 width: 100%;
                 height: auto;
                 z-index: -1;
             }
             #canvas {
                 width: 100%;
                 height: 100%;
                 position: absolute;
                 top: 0;
                 left: 0;
                 opacity: 0.3;
                 z-index: -1;
             }
             .shape-1 {
                 left: 20px;
                 bottom: 10px;
                 height: 150px;
                 width: 150px;
                 position: absolute;
                 border-radius: 50%;
                 z-index: -1;
                 opacity: .5;
                 animation: movebounce 5s linear infinite;
             }
             .shape1 {
                 position: absolute;
                 top: 85px;
                 z-index: -1;
                 left: 30px;
                 animation: movebounce 5s linear infinite;
             }
             .shape2 {
                 position: absolute;
                 top: 85px;
                 z-index: -1;
                 right: 30px;
                 animation: movebounce 5s linear infinite;
             }
             .shape3 {
                 position: absolute;
                 bottom: 85px;
                 z-index: -1;
                 right: 30px;
                 animation: movebounce 5s linear infinite;
             }
             .shape4 {
                 position: absolute;
                 left: 9%;
                 top: 23%;
                 z-index: -1;
                 opacity: .3;
             }
             .shape6 {
                 position: absolute;
                 top: 30%;
                 z-index: -1;
                 left: 30px;
                 animation: rotate3d 4s linear infinite;
                 opacity: .6;
             }
             .shape7 {
                 position: absolute;
                 top: 20%;
                 z-index: -1;
                 left: 0;
                 animation: movebounce 5s linear infinite;
                 opacity: .4;
             }
             .shape8 {
                 position: absolute;
                 z-index: -1;
                 top: 60%;
                 left: 15%;
             }
             .shape9 {
                 position: absolute;
                 left: 45%;
                 top: 40%;
                 z-index: -1;
                 animation: animationFramesOne 15s infinite linear;
             }
             .shape10 {
                 position: absolute;
                 right: 5%;
                 top: 8%;
                 z-index: -1;
                 animation: movebounce 5s linear infinite;
             }
             .shape11 {
                 position: absolute;
                 left: 3%;
                 bottom: 3%;
                 z-index: -1;
             }
             @keyframes movebounce {
                 0% {
                     transform: translateY(0px);
                 }
                 50% {
                     transform: translateY(20px);
                 }
                 100% {
                     transform: translateY(0px);
                 }
             }
             @keyframes moveleftbounce {
                 0% {
                     transform: translateX(0px);
                 }
                 50% {
                     transform: translateX(20px);
                 }
                 100% {
                     transform: translateX(0px);
                 }
             }
             .rotateme {
                 animation-name: rotateme;
                 animation-duration: 40s;
                 animation-iteration-count: infinite;
                 animation-timing-function: linear;
             }
             @keyframes rotateme {
                 from {
                     transform: rotate(0deg);
                 }
                 to {
                     transform: rotate(360deg);
                 }
             }
             @-webkit-keyframes rotateme {
                 from {
                     -webkit-transform: rotate(0deg);
                 }
                 to {
                     -webkit-transform: rotate(360deg);
                 }
             }
             @-moz-keyframes rotateme {
                 from {
                     -moz-transform: rotate(0deg);
                 }
                 to {
                     -moz-transform: rotate(360deg);
                 }
             }
             @-o-keyframes rotateme {
                 from {
                     -o-transform: rotate(0deg);
                 }
                 to {
                     -o-transform: rotate(360deg);
                 }
             }
             @-webkit-keyframes rotate3d {
                 0% {
                     -webkit-transform: rotateY(0deg);
                     transform: rotateY(0deg);
                 }
                 100% {
                     -webkit-transform: rotateY(360deg);
                     transform: rotateY(360deg);
                 }
             }
             @keyframes rotate3d {
                 0% {
                     -webkit-transform: rotateY(0deg);
                     transform: rotateY(0deg);
                 }
                 100% {
                     -webkit-transform: rotateY(360deg);
                     transform: rotateY(360deg);
                 }
             }
             @keyframes animationFramesOne{
                 0% {
                     transform:  translate(0px,0px)  rotate(0deg) ;
                 }
                 20% {
                     transform:  translate(73px,-1px)  rotate(36deg) ;
                 }
                 40% {
                     transform:  translate(141px,72px)  rotate(72deg) ;
                 }
                 60% {
                     transform:  translate(83px,122px)  rotate(108deg) ;
                 }
                 80% {
                     transform:  translate(-40px,72px)  rotate(144deg) ;
                 }
                 100% {
                     transform:  translate(0px,0px)  rotate(0deg) ;
                 }
             }
             @-webkit-keyframes animationFramesOne {
                 0% {
                     -webkit-transform:  translate(0px,0px)  rotate(0deg) ;
                 }
                 20% {
                     -webkit-transform:  translate(73px,-1px)  rotate(36deg) ;
                 }
                 40% {
                     -webkit-transform:  translate(141px,72px)  rotate(72deg) ;
                 }
                 60% {
                     -webkit-transform:  translate(83px,122px)  rotate(108deg) ;
                 }
                 80% {
                     -webkit-transform:  translate(-40px,72px)  rotate(144deg) ;
                 }
                 100% {
                     -webkit-transform:  translate(0px,0px)  rotate(0deg) ;
                 }
             }
             @-webkit-keyframes ripple-white {
                 0% {
                     -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2);
                     box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2)
                 }
                 100% {
                     -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2), 0 0 0 60px rgba(255, 255, 255, 0);
                     box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2), 0 0 0 60px rgba(255, 255, 255, 0)
                 }
             }
             @keyframes ripple-white {
                 0% {
                     -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2);
                     box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2)
                 }
                 100% {
                     -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2), 0 0 0 60px rgba(255, 255, 255, 0);
                     box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2), 0 0 0 40px rgba(255, 255, 255, 0.2), 0 0 0 60px rgba(255, 255, 255, 0)
                 }
             }

             /*================================================
             About CSS
             =================================================*/
.about-content {
    .section-title {
        margin-bottom: 15px;
        text-align: left;

        .bar {
            margin: {
                left: 0;
                right: 0;
            }
        }
        p {
            margin: {
                left: 0;
                right: 0;
            }
        }
    }
}
.about-inner-area {
    margin-top: 100px;

    .about-text {
        h3 {
            margin-bottom: 15px;
            font: {
                size: 23px;
                weight: 700;
            }
        }
    }
}

/*================================================
Features CSS
=================================================*/
.features-area {
    position: relative;
    z-index: 1;

    &.marketing-features {
        .features-inner-area {
            margin-top: 90px;
        }
    }
    &.software-features {
        .single-features {
            position: relative;
            text-align: left;
            padding-left: 85px;

            .icon {
                text-align: center;
                position: absolute;
                left: 0;
                top: 0;
                width: 65px;
                height: 65px;
                line-height: 65px;
                font-size: 25px;
            }
            h3 {
                margin-top: 0;
            }
        }
    }
    &.book-features {
        /* background: #F9FAFC url('assets/img/bg-point.png') no-repeat fixed right -600px center/ auto;*/
     }
 }
 .single-features {
     text-align: center;
     transition: .4s;
     margin-bottom: 40px;

     .icon {
         display: inline-block;
         width: 80px;
         height: 80px;
         line-height: 80px;
         background: #edf5ff;
         border-radius: 50%;
         font-size: 30px;
         color: $template_color;
         transition: .4s;
     }
     h3 {
         margin: 25px 0 15px;
         font: {
             size: 23px;
             weight: 700;
         }
     }
     &:hover {
         transform: translateY(-10px);

         .icon {
             color: $white_color;
             background: $template_color2;
             box-shadow: 0px 10px 30px 0px rgba(26, 38, 74, 0.14);
         }
     }
 }
 .single-holder-box {
     box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
     background: $white_color;
     text-align: center;
     border-radius: 5px;
     padding: 30px;
     transition: .4s;

     h3 {
         font: {
             size: 23px;
             weight: 700;
         }
         margin: {
             top: 25px;
             bottom: 15px;
         }
     }
     img {
         width: 120px;
     }
     &:hover {
         transform: translateY(-7px);
     }
     &.mt-30 {
         margin-top: 30px;
     }
     &.mt-50 {
         margin-top: 50px;
     }
 }
 .features-holder-content  {
     .section-title {
         text-align: left;
         margin: {
             bottom: 15px;
         }
     }
     .bar {
         margin: {
             left: 0;
             right: 0;
         }
     }
     p {
         margin: {
             bottom: 0;
             left: 0;
             right: 0;
         }
     }
     p {
         margin-bottom: 25px;
     }
 }
 .single-features-box {
     margin-bottom: 30px;
     padding: 30px;
     background: $white_color;
     box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
     border-radius: 3px;
     transition: .4s;

     .icon {
         display: inline-block;
         width: 80px;
         height: 80px;
         line-height: 80px;
         background: #edf5ff;
         border-radius: 50%;
         text-align: center;
         font-size: 30px;
         color: $template_color;
         transition: .4s;
     }
     h3 {
         margin: 25px 0 15px;
         font: {
             size: 23px;
             weight: 700;
         }
     }
     &:hover {
         transform: translateY(-10px);

         .icon {
             color: $white_color;
             background: $template_color2;
         }
     }
 }
 .features-inner-area {
     margin-top: 60px;

     .features-inner-content {
         max-width: 540px;

         .features-item {
             margin-bottom: 35px;
             position: relative;
             padding-left: 90px;
             transition: .4s;

             &:last-child {
                 margin-bottom: 0;
             }
             .icon {
                 position: absolute;
                 left: 0;
                 top: 0;
                 width: 70px;
                 height: 70px;
                 line-height: 75px;
                 background: $white_color;
                 border-radius: 50%;
                 font-size: 25px;
                 text-align: center;
                 color: $template_color;
                 box-shadow: 0 0 1.25rem rgba(108,118,134,.1)!important;
                 transition: .4s;
             }
             h3 {
                 margin: 0 0 15px;
                 font: {
                     size: 23px;
                     weight: 700;
                 }
             }
             &:hover {
                 transform: translateY(-10px);

                 .icon {
                     color: $white_color;
                     background: $template_color2;
                 }
             }
         }
     }
 }

 /*================================================
 Product New Features CSS
 =================================================*/
.new-features-area {
    position: relative;
    z-index: 1;
    background: {
        /* image: url(assets/img/cta-bg.jpg);*/
         position: center center;
         size: cover;
         attachment: fixed;
         repeat: no-repeat;
     }
     &::before {
         content: '';
         position: absolute;
         z-index: -1;
         left: 0;
         top: 0;
         width: 100%;
         height: 100%;
         background: $template_color;
         opacity: .87;
     }
 }
 .new-features-content {
     .section-title {
         text-align: left;
         margin-bottom: 45px;

         h2 {
             color: $white_color;
         }
         .bar {
             background: $white_color;
             margin: {
                 bottom: 0;
                 left: 0;
                 right: 0;
             }
             &::before {
                 background: $template_color;
             }
         }
     }
     .single-inner-features {
         position: relative;
         margin-bottom: 30px;
         padding-left: 45px;

         &:last-child {
             margin-bottom: 0;
         }
         i {
             position: absolute;
             left: 0;
             top: 0;
             font-size: 30px;
             color: $white_color;
         }
         h3 {
             margin-bottom: 15px;
             color: $white_color;
             font: {
                 size: 23px;
                 weight: 700;
             }
         }
         p {
             color: #f2f2f2;
         }
     }
 }

 /*================================================
 Services CSS
 =================================================*/
.services-area {
    padding-bottom: 70px;
}
.single-services {
    margin-bottom: 30px;
    background: $white_color;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    transition: .4s;
    padding:  {
        left: 30px;
        right: 30px;
        top: 30px;
        bottom: 30px;
    }
    .icon {
        display: inline-block;
        width: 60px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        background: #edf5ff;
        border-radius: 50%;
        font-size: 25px;
        color: $template_color;
        transition: .4s;
    }
    h3 {
        font: {
            size: 23px;
            weight: 700;
        }
        margin: {
            top: 25px;
            bottom: 15px;
        }
    }
    &:hover {
        transform: translateY(-8px);
        box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

        .icon {
            background: $template_color;
            color: $white_color;
        }
    }
}

/*================================================
Book Discover CSS
=================================================*/
.discover-content {
    h3 {
        margin-bottom: 15px;
        font: {
            size: 35px;
            weight: 700;
        }
    }
    span {
        display: block;
        color: $paragraph_color;
        margin-bottom: 15px;
        letter-spacing: 2px;
        font: {
            size: 20px;
            weight: 400;
        }
    }
    p {
        margin-bottom: 10px;
    }
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        overflow: hidden;

        li {
            color: $paragraph_color;
            float: left;
            width: 45%;
            margin-top: 10px;

            i {
                color: $template_color;
                margin-right: 2px;
            }
        }
    }
}

/*================================================
Overview CSS
=================================================*/
.overview-section {
    position: relative;
    z-index: 1;
    /* background: #F9FAFC url('assets/img/bg-point.png') no-repeat fixed right -600px center/ auto;*/

     .shape7 {
         opacity: 1;
     }
     &.software-overview {
         &.bg-gray {
             background: $white_color;
         }
     }
 }
 .overview-box {
     margin-bottom: 60px;

     .overview-img {
         text-align: center;
     }
     .overview-content {
         .icon {
             background: #ffe9da;
             color: $template_color2;
             display: inline-block;
             width: 50px;
             height: 50px;
             text-align: center;
             line-height: 50px;
             border-radius: 50%;
             font-size: 25px;
         }
         span {
             display: block;
             margin-bottom: -10px;
             color: $template_color2;
         }
         h3 {
             position: relative;
             margin: 20px 0 15px;
             z-index: 1;
             font: {
                 size: 23px;
                 weight: 700;
             }
         }
         p {
             margin-bottom: 0;
         }
         ul {
             padding: 0;
             margin: 15px 0 25px;
             list-style-type: none;

             li {
                 color: $paragraph_color;
                 padding-left: 18px;
                 position: relative;
                 margin-bottom: 10px;

                 &::before {
                     content: '';
                     position: absolute;
                     left: 0;
                     top: 8px;
                     width: 8px;
                     height: 8px;
                     border-radius: 50%;
                     background: $template_color;
                 }
                 &:last-child {
                     margin-bottom: 0;
                 }
             }
             &.features-list {
                 overflow: hidden;
                 margin: {
                     top: 20px;
                     bottom: 0;
                 }
                 li {
                     float: left;
                     width: 45%;
                     background: $white_color;
                     box-shadow: 0 0 1.05rem rgba(108, 118, 134, 0.1);
                     padding: 10px 20px 10px 25px;
                     border-radius: 5px;
                     margin: {
                         bottom: 0;
                         right: 10px;
                         top: 10px;
                     }
                     &::before {
                         left: 10px;
                         top: 18px;
                     }
                 }
             }
         }
     }
     &.modern-flexible {
         .single-item {
             position: relative;
             padding-left: 55px;
             margin-bottom: 30px;

             .icon {
                 position: absolute;
                 left: 0;
                 top: 0;
                 font-size: 30px;
                 color: $template_color;
             }
             h3 {
                 margin-bottom: 15px;
                 font: {
                     size: 23px;
                     weight: 700;
                 }
             }
             &:last-child {
                 margin-bottom: 0;
             }
             &.right-icon {
                 text-align: right;
                 padding: {
                     left: 0;
                     right: 55px;
                 }
                 .icon {
                     left: auto;
                     right: 0;
                 }
             }
         }
     }
     &:last-child {
         margin-bottom: 0;
     }
     .app-fetured-item {
         position: relative;
         height: 100%;

         .app-item {
             text-align: center;
             width: 200px;
             padding: 45px 10px;
             border-radius: 5px;
             animation: movebounce 5s linear infinite;
             position: absolute;

             &.item-one {
                 background-image: -webkit-linear-gradient(40deg,#e87e16 0,#f0de14 100%);
                 box-shadow: 0 20px 40px 0 rgba(224,149,32,.4);
                 right: 0;
                 top: -150px;
                 z-index: 1;
             }
             &.item-two {
                 background-image: -webkit-linear-gradient(40deg,#13C4A1 0,#41CBB0 100%);
                 box-shadow: 0 20px 40px 0 rgba(19,196,161,.4);
                 left: 60px;
                 top: 0;
             }
             &.item-three {
                 background-image: -webkit-linear-gradient(40deg,#008000 0,#268B26 100%);
                 box-shadow: 0 20px 40px 0 rgba(0,128,0,.4);
                 left: 90px;
                 bottom: -10px;
             }
             &.item-four {
                 background-image: -webkit-linear-gradient(40deg,#FF8A73 0,#F89B88 100%);
                 box-shadow: 0 20px 40px 0 rgba(255,138,115,.4);
                 right: 35px;
                 top: -15px;
             }
             i {
                 font-size: 40px;
                 color: $white_color;
             }
             h6 {
                 color: $white_color;
                 font: {
                     size: 16px;
                     weight: 700;
                 }
                 margin: {
                     top: 10px;
                     bottom: 0;
                 }
             }
         }
     }
 }

 /*================================================
 CTA CSS
 =================================================*/
.cta-area {
    position: relative;
    z-index: 1;
    padding: 60px 0;
    background: {
        /* image: url(assets/img/cta-bg.jpg);*/
         position: center center;
         size: cover;
         attachment: fixed;
         repeat: no-repeat;
     }
     &::before {
         content: '';
         position: absolute;
         z-index: -1;
         left: 0;
         top: 0;
         width: 100%;
         height: 100%;
         background: $template_color;
         opacity: .87;
     }
     p {
         color: $white_color;
         font: {
             size: 25px;
             weight: 700;
         }
     }
     .btn {
         &:hover {
             &::after, &::before {
                 background: $white_color;
             }
         }
     }
     .btn-primary {
         &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
             color: $template_color2;
             background: $white_color;
         }
         &:hover, &:focus {
             background: $white_color;
             color: $template_color2;
         }
     }
 }

 /*================================================
 SAAS Tools CSS
 =================================================*/
.saas-tools {
    position: relative;
    z-index: 1;

    .bubble-animate {
        .circle {
            &.small {
                opacity: .1;
            }
            &.medium {
                opacity: .1;
            }
        }
    }
}
.tab {
    overflow: hidden;
    background: $white_color;
    padding: 30px;
    box-shadow: 0 6px 13px 0 rgba(137, 173, 255, 0.35);
    border-radius: 5px;

    .tabs {
        list-style-type: none;
        margin-bottom: 60px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: {
            left: 60px;
            right: 60px;
        }
        li {
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
            text-align: center;
            padding: {
                right: 15px;
                left: 15px;
            }
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
            a {
                text-decoration: none;
                display: inline-block;
                font: {
                    size: 18px;
                    weight: 700;
                }
                i {
                    display: inline-block;
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    background: #ffe9da;
                    color: $template_color2;
                    text-align: center;
                    font-size: 25px;
                    border-radius: 50%;
                    margin-bottom: 15px;
                    -moz-transition: .4s;
                    transition: .4s;
                }
                &:hover {
                    color: $template_color;

                    i {
                        color: $white_color;
                        background: $template_color;
                    }
                }
            }
            &.current {
                i {
                    color: $white_color;
                    background: $template_color;
                }
                a {
                    color: $template_color;
                }
            }
        }
    }
    .tab_content {
        .tabs_item {
            display: none;

            &:first-child {
                display: block;
            }
            .tabs_item_content {
                h3 {
                    margin-bottom: 15px;
                    font: {
                        size: 23px;
                        weight: 700;
                    }
                }
                p {
                    margin-bottom: 0;
                }
                ul {
                    padding: 0;
                    margin: 0;
                    list-style-type: none;
                    margin-top: 15px;

                    li {
                        margin-top: 10px;
                        font-weight: 400;
                        color: $paragraph_color;

                        &:first-child {
                            margin-top: 0;
                        }
                        i {
                            margin-right: 3px;
                            font-size: 14px;
                            color: $template_color;
                        }
                    }
                }
            }
        }
    }
}

/*================================================
Fun Facts CSS
=================================================*/
.single-funfact {
    position: relative;
    text-align: center;
    border: 1px solid #edf5ff;
    transition: .4s;
    border-radius: 5px;
    padding: {
        left: 30px;
        top: 30px;
        bottom: 30px;
        right: 30px;
    }
    .icon {
        display: inline-block;
        width: 70px;
        height: 70px;
        line-height: 70px;
        background: #edf5ff;
        border-radius: 50%;
        font-size: 25px;
        color: $template_color;
        transition: .4s;
    }
    h3 {
        margin: 25px 0 8px;
        font: {
            size: 35px;
            weight: 700;
        }
    }
    p {
        font-size: 17px;
    }
    &:hover {
        box-shadow: 0 10px 55px 5px rgba(137,173,255,.35);
        transform: translate(0,-10px);
        background-color: $white_color;

        .icon {
            background: $template_color;
            color: $white_color;
        }
    }
}
.single-funfact-box {
    position: relative;
    padding-left: 90px;

    .icon {
        position: absolute;
        left: 0;
        top: 0;
        width: 65px;
        text-align: center;
        height: 65px;
        line-height: 65px;
        background: #edf5ff;
        border-radius: 50%;
        font-size: 25px;
        color: $template_color;
        transition: .4s;
    }
    h3 {
        margin: 0 0 6px;
        color: $white_color;
        font: {
            size: 35px;
            weight: 700;
        }
    }
    p {
        font-size: 17px;
        color: $white_color;
    }
    &:hover {
        .icon {
            background: $template_color2;
            color: $white_color;
        }
    }
}
.fun-facts-inner-area {
    margin-top: 100px;

    .fun-facts-inner-content {
        max-width: 540px;

        h3 {
            margin: 0 0 15px;
            font: {
                size: 35px;
                weight: 700;
            }
        }
        p {
            margin-bottom: 15px;
        }
        ul {
            padding: 0;
            margin: 0 0 25px;
            list-style-type: none;

            li {
                color: $paragraph_color;
                padding-left: 18px;
                position: relative;
                margin-bottom: 10px;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 8px;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: $template_color;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.app-funfacts-area {
    position: relative;
    z-index: 1;
    background: {
        /* image: url(assets/img/cta-bg.jpg);*/
         position: center center;
         size: cover;
         attachment: fixed;
         repeat: no-repeat;
     }
     padding: {
         bottom: 70px;
     }
     &::before {
         content: '';
         position: absolute;
         z-index: -1;
         left: 0;
         top: 0;
         width: 100%;
         height: 100%;
         background: $template_color;
         opacity: .87;
     }
     .single-funfact {
         border: none;
         padding: 0;
         margin-bottom: 30px;

         h3 {
             color: $white_color;
         }
         p {
             color: $white_color;
         }
         &:hover {
             background: transparent;
             box-shadow: unset;

             .icon {
                 background: $template_color2;
                 color: $white_color;
             }
         }
     }
 }

 /*================================================
 Work Process CSS
 =================================================*/
.saas-work-process {
    position: relative;
    z-index: 1;
    background: {
        /* image: url(assets/img/cta-bg.jpg);*/
         position: center center;
         size: cover;
         attachment: fixed;
         repeat: no-repeat;
     }
     &::before {
         content: '';
         position: absolute;
         z-index: -1;
         left: 0;
         top: 0;
         width: 100%;
         height: 100%;
         background: $template_color;
         opacity: .87;
     }
     .section-title {
         h2, p {
             color: $white_color;
         }
         .bar {
             background: $white_color;

             &::before {
                 background: $template_color;
             }
         }
     }
 }
 .single-work-process {
     position: relative;
     padding: 30px;
     background: $white_color;
     border-radius: 3px;
     box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
     transition: .4s;
     z-index: 1;

     &::before {
         content: '';
         position: absolute;
         z-index: -1;
         background: $white_color;
         width: 96%;
         opacity: .62;
         height: 50%;
         bottom: -6px;
         left: 0;
         right: 0;
         margin: auto;
         border-radius: 3px;
         transition: .4s;
     }
     .icon {
         display: inline-block;
         width: 60px;
         height: 60px;
         text-align: center;
         line-height: 60px;
         background: #edf5ff;
         border-radius: 50%;
         font-size: 25px;
         color: $template_color;
         transition: .4s;
     }
     h3 {
         margin: 25px 0 15px;
         font: {
             size: 23px;
             weight: 700;
         }
     }
     &:hover {
         box-shadow: 0 10px 55px 5px rgba(137,173,255,.35);
         transform: translate(0,-10px);
         background-color: $white_color;

         .icon {
             background: $template_color;
             color: $white_color;
         }
         &::before {
             bottom: -5px;
         }
     }
 }

 /*================================================
 Pricing CSS
 =================================================*/
.pricing-area {
    position: relative;
    z-index: 1;

    &.book-pricing {
        .pricing-table {
            .price-footer {
                margin-top: 30px;
            }
        }
    }
}
.pricingTable {
    text-align: center;
    background: $white_color;
    padding: 50px 0 30px;
    margin-top: 40px;
    border-radius: 5px;
    position: relative;
    transition: all 0.5s ease 0s;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

    .pricing-icon {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        line-height: 73px;
        background: $white_color;
        color: $template_color2;
        position: absolute;
        top: -40px;
        left: 50%;
        box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
        font-size: 30px;
        transform: translateX(-50%);
        transition: all 0.5s ease 0s;
    }
    .price-Value {
        display: inline-block;
        position: relative;
        font: {
            size: 45px;
            weight: 700;
        }
        .currency {
            position: absolute;
            top: 3px;
            left: -35px;
            font: {
                size: 18px;
                weight: 900;
            }
        }
    }
    .month {
        display: inline-block;
        text-transform: capitalize;
        font: {
            size: 18px;
            weight: 700;
        }
    }
    .pricingHeader {
        position: relative;
        z-index: 1;

        &::after {
            content: "";
            width: 100%;
            height: 1px;
            background: $template_color;
            position: absolute;
            top: 50%;
            left: 0;
            z-index: -1;
            transition: all 0.5s ease 0s;
        }
        .title {
            width: 50%;
            background: $template_color;
            margin: 15px auto 10px;
            padding: 10px 0;
            color: $white_color;
            text-transform: uppercase;
            position: relative;
            z-index: 1;
            border-radius: 5px;
            transition: all 0.5s ease 0s;
            font: {
                size: 18px;
                weight: 700;
            }
        }
    }
    .pricing-content {
        margin: {
            top: 30px;
            bottom: 35px;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                margin-bottom: 10px;
                text-decoration: line-through;
                color: #CCDCEA;

                &:last-child {
                    margin-bottom: 0;
                }
                &.active {
                    text-decoration: none;
                    color: $paragraph_color;
                }
            }
        }
    }
    &:hover {
        box-shadow: 0 10px 55px 5px rgba(137,173,255,.35);
        transform: translate(0,-10px);

        .pricing-icon {
            background: $template_color;
            color: $white_color;
        }
        .pricingHeader {
            &::after {
                background: $template_color2;
            }
            .title {
                color: $white_color;
                background: $template_color2;
            }
        }
    }
}
/* Pricing Table Style Two */
.pricing-table {
    text-align: center;
    padding: 30px 0;
    -o-transition: .3s ease-in;
    transition: .3s ease-in;
    position: relative;
    z-index: 1;
    background-color: $template_color;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);

    .price-header {
        .title {
            color: $white_color;
            text-transform: uppercase;
            margin-bottom: 30px;
            font: {
                size: 23px;
                weight: 700;
            }
        }
        .price {
            transition: .3s ease-in;
            text-align: left;
            position: relative;
            padding: 15px 0 15px 65px;
            z-index: 1;
            letter-spacing: 2px;
            font: {
                size: 45px;
                weight: 700;
            }
            &::after {
                position: absolute;
                left: -20%;
                top: 0;
                width: 100%;
                background-color: $white_color;
                border-radius: 25px;
                content: '';
                height: 100%;
                -webkit-transform: skewX(-35deg);
                -ms-transform: skewX(-35deg);
                transform: skewX(-35deg);
                z-index: -1;
            }
            .dollar {
                position: relative;
                top: -25px;
                font: {
                    size: 18px;
                    weight: 900;
                }
            }
            .month {
                font: {
                    size: 18px;
                    weight: 700;
                }
            }
        }
    }
    .price-body {
        ul {
            margin: 30px 0;
            padding: 0;
            list-style-type: none;

            li {
                color: $white_color;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .price-footer {
        .btn {
            &:hover {
                &::after, &::before {
                    background: $white_color;
                }
            }
        }
        .btn-primary {
            &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
                color: $template_color2;
                background: $white_color;
            }
            &:hover, &:focus {
                background: $white_color;
                color: $template_color2;
            }
        }
    }
    &:hover {
        transform: translateY(-7px);
    }
}
/* Pricing Table Style Three */
.pricing-table-box {
	padding-bottom: 30px;
	background: $white_color;
	text-align: center;
	z-index: 1;
	position: relative;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 7px 5px 30px 0 rgba(72,73,121,0.15);
    transition: .4s;
    background: {
        /* image: url(assets/img/patt.png);*/
         position: center center;
     }
     .pricingTable-header {
         padding: 30px 15px 45px;
         background: $template_color;
         clip-path: polygon(50% 100%, 100% 60%, 100% 0, 0 0, 0 60%);
         position: relative;

         &::before {
             content: "";
             width: 400px;
             height: 400px;
             border-radius: 50%;
             position: absolute;
             right: -50%;
             top: -130%;
             background: repeating-radial-gradient(rgba(255,255,255,0.05), rgba(255,255,255,0.2) 20%);
             transition: .4s;
         }
     }
     .title {
         color: $white_color;
         text-transform: uppercase;
         margin-bottom: 15px;
         font: {
             size: 23px;
             weight: 700;
         }
     }
     .price-value {
         display: block;
         color: $white_color;
         margin-bottom: 30px;
         font: {
             size: 45px;
             weight: 700;
         }
         span {
             font-size: 15px;
             text-transform: uppercase;
             margin-left: -10px;
         }
         sup {
             font-size: 23px;
             top: -25px;
         }
     }
     .pricing-content {
         padding: 30px 25px;
         margin-bottom: 0;
         list-style-type: none;

         li {
             color: #828893;
             text-transform: capitalize;
             border-bottom: 1px solid #eee;
             margin-bottom: 12px;
             padding-bottom: 12px;

             &:last-child {
                 border-bottom: none;
                 padding-bottom: 0;
                 margin-bottom: 0;
             }
         }
     }
     &:hover {
         transform: translateY(-10px);

         .pricingTable-header {
             &::before {
                 right: 50%;
             }
         }
     }
 }

 /*================================================
 Project CSS
 =================================================*/
.project-area {
    position: relative;
    z-index: 1;

    .owl-theme {
        .owl-nav {
            margin-top: 15px;
            line-height: .01;
            margin-bottom: -8px;

            [class*=owl-] {
                color: $template_color2;
                font-size: 30px;
                margin: 0 3px;
                padding: 0;
                background: transparent;
                display: inline-block;
                cursor: pointer;
                border-radius: 0;
                transition: .4s;

                &:hover {
                    color: $template_color;
                }
            }
        }
        .owl-controls {
            line-height: .1;
        }
    }
}
.single-project {
    text-align: center;
    background: $white_color;
    box-shadow: 0 10px 50px 0 rgba(46,65,90,.1);
    margin-bottom: 30px;
    border-radius: 5px;

    .project-content {
        text-align: left;
        padding: 30px;

        h3 {
            margin: 0;
            font: {
                size: 23px;
                weight: 700;
            }
        }
        span {
            display: block;
            margin-bottom: 10px;
            color: $template_color2;
        }
    }
    .project-image {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: $black_color;
            opacity: 0;
            visibility: hidden;
            border-radius: 5px;
            transform: scale(.8);
            transition: .4s;
        }
        a {
            position: absolute;
            left: 0;
            top: 50%;
            right: 0;
            margin: 0 auto;
            font-size: 30px;
            color: $white_color;
            opacity: 0;
            visibility: hidden;
            margin-top: -20px;
            transition: .4s;
            transform: scale(0);

            &:hover {
                color: $template_color;
            }
        }
        img {
            transform-style: unset !important;
            border-radius: 5px;
        }
    }
    &:hover {
        .project-image {
            &::before {
                visibility: visible;
                opacity: .8;
                transform: scale(1);
            }
            a {
                visibility: visible;
                opacity: 1;
                transform: scale(1);
            }
        }
    }
}

/*================================================
Project Details CSS
=================================================*/
.project-details-image {
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
    text-align: center;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $black_color;
        opacity: 0;
        visibility: hidden;
        transition: .4s;
    }
    a {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 50%;
        color: $white_color;
        font-size: 30px;
        margin-top: 0;
        opacity: 0;
        visibility: hidden;
    }
    &:hover {
        &::before {
            opacity: .77;
            visibility: visible;
        }
        a {
            opacity: 1;
            visibility: visible;
            margin-top: -20px;
        }
    }
}
.project-details-desc {
    h3 {
        font: {
            size: 23px;
            weight: 700;
        }
        margin: {
            bottom: 15px;
            top: 25px;
        }
    }
    p {
        margin-bottom: 15px;
    }
    .project-details-information {
        overflow: hidden;

        .single-info-box {
            float: left;
            width: 20%;
            margin-top: 30px;

            h4 {
                margin-bottom: 15px;
                font: {
                    size: 23px;
                    weight: 700;
                }
            }
            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;

                li {
                    display: inline-block;

                    a {
                        margin-right: 4px;
                        color: $paragraph_color;

                        &:hover {
                            color: $template_color;
                        }
                    }
                }
            }
        }
    }
}

/*================================================
Gallery CSS
=================================================*/
.single-image {
    text-align: center;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $black_color;
        opacity: 0;
        visibility: hidden;
        transform: scale(.8);
        transition: .4s;
    }
    a {
        position: absolute;
        left: 0;
        top: 50%;
        right: 0;
        margin: 0 auto;
        font-size: 30px;
        color: $white_color;
        opacity: 0;
        visibility: hidden;
        margin-top: -20px;
        transition: .4s;
        transform: scale(0);

        &:hover {
            color: $template_color;
        }
    }
    &:hover {
        &::before {
            visibility: visible;
            opacity: .8;
            transform: scale(1);
        }
        a {
            visibility: visible;
            opacity: 1;
            transform: scale(1);
        }
    }
}

/*================================================
Get Started CSS
=================================================*/
.get-started {
    position: relative;
    z-index: 1;
}
.get-started-form {
    max-width: 600px;
    margin: 0 auto;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    background: $white_color;
    padding: 40px;
    text-align: center;
    border-radius: 3px;

    form {
        .btn {
            display: block;
            width: 100%;
            letter-spacing: 2px;
        }
        p {
            margin-top: 15px;

            a {
                color: #50a1ff;

                &:hover {
                    color: $template_color;
                }
            }
        }
    }
}

/*================================================
How App Works CSS
=================================================*/
.how-app-work {
    position: relative;
    z-index: 1;
    background: {
        /* image: url(assets/img/cta-bg.jpg);*/
         position: center center;
         size: cover;
         attachment: fixed;
         repeat: no-repeat;
     }
     &::before {
         content: '';
         position: absolute;
         z-index: -1;
         left: 0;
         top: 0;
         width: 100%;
         height: 100%;
         background: $template_color;
         opacity: .87;
     }
     .section-title {
         h2, p {
             color: $white_color;
         }
         .bar {
             background: $white_color;

             &::before {
                 background: $template_color;
             }
         }
     }
     .app-mobile {
         text-align: center;
         margin-bottom: 60px;
         animation: movebounce 5s linear infinite;
     }
 }
 .single-app-works {
     text-align: center;
     transition: .4s;

     .icon {
         display: inline-block;
         width: 80px;
         height: 80px;
         line-height: 80px;
         background: #edf5ff;
         border-radius: 50%;
         text-align: center;
         font-size: 30px;
         color: $template_color;
         transition: .4s;
     }
     h3 {
         color: $white_color;
         margin: {
             bottom: 15px;
             top: 25px;
         }
         font: {
             size: 23px;
             weight: 700;
         }
     }
     p {
         color: $white_color;
     }
     &:hover {
         transform: translateY(-7px);

         .icon {
             background: $template_color2;
             color: $white_color;
         }
     }
 }

 /*================================================
 Screenshot CSS
 =================================================*/
.screenshot-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 135px;

            [class*=owl-] {
                color: $template_color2;
                font-size: 30px;
                margin: 0 3px;
                padding: 0;
                background: transparent;
                display: inline-block;
                cursor: pointer;
                border-radius: 0;
                transition: .4s;

                &:hover {
                    color: $template_color;
                }
            }
        }
        .owl-controls {
            line-height: .1;
        }
    }
}
.screenshot-contain {
    position: relative;
    margin-top: 82px;

    .mobile-light {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        margin: 0 auto;
        width: auto;
        height: auto;
        top: -82px;
        z-index: 2;
    }
    .screenshot-item {
        img {
            transform-style: unset !important;
            width: unset !important;
        }
    }
}
.page-from-book {
    .owl-theme {
        .owl-nav {
            margin-top: 50px;
        }
    }
    .screenshot-item {
        position: relative;
        text-align: center;

        a {
            display: block;
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: $black_color;
            opacity: 0;
            visibility: hidden;
            transform: scale(.8);
            transition: .4s;
        }
        .popup-btn {
            position: absolute;
            left: 0;
            top: 50%;
            right: 0;
            margin: 0 auto;
            font-size: 30px;
            color: $white_color;
            opacity: 0;
            display: inline-block;
            visibility: hidden;
            margin-top: -20px;
            transition: .4s;
            transform: scale(0);

            &:hover {
                color: $template_color;
            }
        }
        img {
            transform-style: unset !important;
            width: unset !important;
        }
        &:hover {
            &::before {
                visibility: visible;
                opacity: .8;
                transform: scale(1);
            }
            .popup-btn {
                visibility: visible;
                opacity: 1;
                transform: scale(1);
            }
        }
    }
}

/*================================================
Team CSS
=================================================*/
.team-area {
    padding-bottom: 70px;
}
.team-slides {
    &.owl-theme {
        .owl-nav {
            line-height: .01;
            margin: {
                top: 10px;
                bottom: 22px;
            }
            [class*=owl-] {
                color: $template_color2;
                font-size: 30px;
                margin: 0 3px;
                padding: 0;
                background: transparent;
                display: inline-block;
                cursor: pointer;
                border-radius: 0;
                transition: .4s;

                &:hover {
                    color: $template_color;
                }
            }
        }
        .owl-controls {
            line-height: .1;
        }
    }
}
.single-team-member {
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    margin-bottom: 30px;
    background: $white_color;
    text-align: center;
    border-radius: 5px;

    img {
        border-radius: 5px 5px 0 0;
    }
    .team-content {
        padding: 30px;

        h3 {
            font: {
                size: 23px;
                weight: 700;
            }
            margin-bottom: 8px;
        }
        span {
            display: block;
            color: $paragraph_color;
            font-size: 15px;
        }
        ul {
            padding: 0;
            margin: 15px 0 0;
            list-style-type: none;

            li {
                display: inline-block;

                a {
                    width: 32px;
                    height: 32px;
                    text-align: center;
                    line-height: 32px;
                    border: 1px solid $paragraph_color;
                    border-radius: 50%;
                    font-size: 14px;
                    margin: 0 3px;
                    color: $paragraph_color;

                    &:hover {
                        color: $white_color;
                        background: $template_color;
                        border-color: $template_color;
                    }
                    &.facebook {
                        border-color: #5580FF;
                        color: #5580FF;

                        &:hover {
                            background-color: #5580FF;
                            color: $white_color;
                        }
                    }
                    &.instagram {
                        border-color: $black_color;
                        color: $black_color;

                        &:hover {
                            background-color: $black_color;
                            color: $white_color;
                        }
                    }
                    &.twitter {
                        border-color: #FF8A73;
                        color: #FF8A73;

                        &:hover {
                            background-color: #FF8A73;
                            color: $white_color;
                        }
                    }
                    &.linkedin {
                        border-color: #FFD474;
                        color: #FFD474;

                        &:hover {
                            background-color: #FFD474;
                            color: $white_color;
                        }
                    }
                }
            }
        }
    }
}
.single-team-member-box {
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    margin-bottom: 30px;
    background: $white_color;
    text-align: center;
    border-radius: 5px;
    padding: 30px;

    img {
        clip-path: polygon(50% 0%, 38.11% 6.7%, 24.99% 6.72%, 18.06% 18.44%, 6.7% 25%, 6.56% 38.64%, 0% 50%, 6.7% 61.89%, 6.7% 75%, 18.44% 81.94%, 25% 93.3%, 38.64% 93.44%, 50% 100%, 61.88% 93.3%, 75% 93.3%, 81.94% 81.56%, 93.3% 74.9%, 93.44% 61.36%, 100% 50%, 93.3% 38.11%, 93.3% 25%, 81.56% 18.06%, 74.96% 6.7%, 61.36% 6.56%, 50% 0%);
    }
    .team-content {
        margin-top: 30px;

        h3 {
            margin-bottom: 8px;
            font: {
                size: 23px;
                weight: 700;
            }
        }
        span {
            display: block;
            color: $paragraph_color;
            font-size: 15px;
        }
        ul {
            padding: 0;
            margin: 15px 0 0;
            list-style-type: none;

            li {
                display: inline-block;

                a {
                    font-size: 18px;
                    color: $paragraph_color;
                    margin: 0 4px;

                    &:hover {
                        transform: translateY(-5px);
                    }
                    &.facebook {
                        color: #5580FF;
                    }
                    &.twitter {
                        color: #FF8A73;
                    }
                    &.linkedin {
                        color: #FFD474;
                    }
                    &.instagram {
                        color: $black_color;
                    }
                }
            }
        }
    }
}

/*================================================
Next Generation CSS
=================================================*/
.next-generation-virtual-reality {
    padding-bottom: 60px;

    .single-item {
        margin-bottom: 40px;
        position: relative;
        padding-left: 55px;

        .icon {
            position: absolute;
            left: 0;
            top: 0;
            font-size: 35px;
            color: $template_color;
            transition: .4s;
        }
        h3 {
            margin-bottom: 15px;
            font: {
                size: 23px;
                weight: 700;
            }
        }
        &:hover {
            .icon {
                color: $template_color2;
            }
        }
    }
}

/*================================================
Feedback CSS
=================================================*/
.feedback-area {
    position: relative;
    z-index: 1;
    overflow: hidden;

    .svg-feedback-bottom {
        position: absolute;
        bottom: 0;
        left: 0px;
        width: 100%;
        height: auto;
        z-index: -1;
    }
}
.single-feedback {
    position: relative;
    padding: 30px;
    background: $white_color;
    transition: .4s;
    text-align: center;
    border-radius: 3px;
    z-index: 1;
    margin: {
        bottom: 30px;
        top: 50px;
    }
    .client-info {
        img {
            clip-path: polygon(50% 0%, 38.11% 6.7%, 24.99% 6.72%, 18.06% 18.44%, 6.7% 25%, 6.56% 38.64%, 0% 50%, 6.7% 61.89%, 6.7% 75%, 18.44% 81.94%, 25% 93.3%, 38.64% 93.44%, 50% 100%, 61.88% 93.3%, 75% 93.3%, 81.94% 81.56%, 93.3% 74.9%, 93.44% 61.36%, 100% 50%, 93.3% 38.11%, 93.3% 25%, 81.56% 18.06%, 74.96% 6.7%, 61.36% 6.56%, 50% 0%);
            position: absolute;
            top: -50px;
            left: 0;
            right: 0;
            box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
            margin: 0 auto;
            width: 115px;
            height: 115px;
        }
        h3 {
            font: {
                size: 23px;
                weight: 700;
            }
            margin: {
                bottom: 5px;
                top: 55px;
            }
        }
        span {
            color: $template_color2;
            font-size: 15px;
        }
    }
    p {
        margin-top: 25px;
    }
    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 0;
        width: 0;
        height: 4px;
        background: $template_color;
        border-radius: 0 0 3px 3px;
        transition: .4s;
    }
    &:hover {
        box-shadow: 0 0 20px 5px rgba(137, 173, 255, 0.35);
        background-color: $white_color;
    }
}
.single-feedback-box {
    background: $white_color;
    box-shadow: 0 10px 50px 0 rgba(46, 65, 90, 0.1);
    border-radius: 5px;
    position: relative;
    padding: 30px 30px 30px 45px;
    margin: {
        bottom: 30px;
        left: 30px;
    }
    .client-info {
        img {
            position: absolute;
            left: -30px;
            width: 60px !important;
            height: 60px;
            top: 30px;
            border-radius: 50%;
            border: 3px solid $white_color;
        }
        h3 {
            font: {
                size: 23px;
                weight: 700;
            }
            margin: {
                bottom: 5px;
            }
        }
        span {
            color: $template_color2;
            font-size: 15px;
        }
    }
    p {
        margin-top: 20px;
    }
}
.feedback-slides {
    .owl-item {
        .single-feedback {
            opacity: .5;
            transition: .5s;

            &::before {
                opacity: 0;
                visibility: hidden;
            }
        }
        &.active {
            &.center {
                .single-feedback {
                    opacity: 1;
                    transition: .5s;
                    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);

                    &::before {
                        opacity: 1;
                        visibility: visible;
                        width: 100%;
                    }
                }
                .single-feedback-box {
                    margin-top: 30px;
                }
            }
        }
    }
    &.owl-theme {
        .owl-nav {
            line-height: .01;
            margin-top: 20px;
            margin-bottom: -8px;

            [class*=owl-] {
                color: $template_color2;
                font-size: 30px;
                margin: 0 3px;
                padding: 0;
                background: transparent;
                display: inline-block;
                cursor: pointer;
                border-radius: 0;
                transition: .4s;

                &:hover {
                    color: $template_color;
                }
            }
        }
        .owl-controls {
            line-height: .1;
        }
    }
}

/*================================================
App Download CSS
=================================================*/
.app-download {
    position: relative;
    z-index: 1;

    .col-lg-6 {
        position: relative;
    }
    .section-title {
        text-align: left;
        margin-bottom: 0;

        .bar {
            margin: {
                left: 0;
                right: 0;
            }
        }
        p {
            margin: {
                left: 0;
                right: 0;
                bottom: 35px;
            }
        }
        .download-btn {
            a {
                position: relative;
                padding: 12px 35px 12px 65px;
                background: $template_color2;
                color: $white_color;
                border-radius: 70px;
                margin-right: 5px;
                box-shadow: 0 13px 27px 0 rgba(163, 48, 53, 0.25);
                font: {
                    weight: 300;
                    size: 15px;
                }
                i {
                    position: absolute;
                    left: 25px;
                    top: 50%;
                    font-size: 25px;
                    margin-top: -12px;
                }
                span {
                    display: block;
                    font: {
                        weight: 700;
                        size: 16px;
                    }
                }
                &:hover, &:focus {
                    background: $template_color;
                    transform: translateY(-5px);
                }
            }
        }
    }
    .download-image {
        position: absolute;
        right: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        bottom: 295px;

        img {
            animation: movebounce 5s linear infinite;
            position: absolute;
            left: -75px;
            top: 0;
            width: 445px;

            &:last-child {
                left: 225px;
                top: 25px;
            }
        }
    }
}

/*================================================
Platform Connect CSS
=================================================*/
.platform-connect {
    /* background: $white_color url('assets/img/bg-point.png') no-repeat fixed left -600px center/ auto;*/

     h2 {
         text-align: center;
         max-width: 520px;
         font: {
             size: 35px;
             weight: 700;
         }
         margin: {
             bottom: 60px;
             left: auto;
             right: auto;
         }
     }
     .box {
         display: block;
         box-shadow: 0 10px 55px 5px rgba(137,173,255,.35);
         position: relative;
         padding: 15px 15px 15px 80px;
         border-radius: 5px;
         transition: .4s;
         background: $white_color;

         img {
             position: absolute;
             left: 15px;
             top: 15px;
         }
         h3 {
             margin-bottom: 5px;
             font: {
                 size: 23px;
                 weight: 700;
             }
         }
         span {
             text-transform: uppercase;
             color: $paragraph_color;
             font-size: 14px;
         }
         i {
             position: absolute;
             right: 15px;
             top: 50%;
             margin-top: -8px;
             color: $paragraph_color;
             transition: .4s;
             font-size: 25px;
         }
         &:hover {
             transform: translateY(-10px);

             i {
                 color: $template_color;
             }
         }
     }
 }

 /*================================================
 Blog CSS
 =================================================*/
.blog-area {
    /* background: #F9FAFC url('assets/img/bg-point.png') no-repeat fixed right -600px center/ auto;*/

     &.blog-section {
         background: unset;
     }

     .owl-theme {
         .owl-nav {
             margin-top: 0;
             margin-bottom: -8px;
             line-height: .01;

             [class*=owl-] {
                 color: $template_color2;
                 font-size: 30px;
                 margin: 0 3px;
                 padding: 0;
                 background: transparent;
                 display: inline-block;
                 cursor: pointer;
                 border-radius: 0;
                 transition: .4s;

                 &:hover {
                     color: $template_color;
                 }
             }
         }
         .owl-controls {
             line-height: .1;
         }
     }
 }
 .single-blog-post {
     background: $white_color;
     margin-bottom: 30px;
     box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
     height: 590px; /* Stellen Sie dies auf die gewünschte Höhe ein */
     overflow: auto; /* Damit scrollt der Inhalt, wenn er die Höhe übersteigt */

     .post-image {
         display: block;
     }
     .blog-post-content {
         padding: 30px;

         ul {
             padding: 0;
             list-style-type: none;
             margin: 0;

             li {
                 display: inline-block;
                 margin-right: 10px;
                 font-size: 15px;
                 color: $paragraph_color;

                 &:last-child {
                     margin-right: 0;
                 }
                 i {
                     color: $template_color;
                     margin-right: 4px;
                 }
                 a {
                     color: $paragraph_color;

                     &:hover {
                         color: $template_color;
                     }
                 }
             }
         }
         h3 {
             line-height: 30px;
             margin: {
                 top: 15px;
                 bottom: 15px;
             }
             font: {
                 size: 23px;
                 weight: 700;
             }
         }
         p {
             margin-bottom: 20px;
         }
         .read-more-btn {
             font-weight: 700;
         }
     }
 }

 /*================================================
 Blog Details CSS
 =================================================*/
.blog-details-area {
    position: relative;

    .blog-details {
        box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
        background: $white-color;

        .article-img {
            position: relative;

            .date {
                position: absolute;
                bottom: 15px;
                width: 100px;
                right: 15px;
                height: 100px;
                text-align: center;
                background: $template_color;
                color: $white-color;
                border-radius: 5px;
                padding-top: 19px;
                line-height: 32px;
                font: {
                   size: 25px;
                   style: italic;
                   weight: 700;
                }
            }
        }
        .article-content {
            padding: 30px;

            ul {
                li {
                    display: inline-block;
                }
                &.category {
                    margin-bottom: 30px;

                    li {
                        margin-right: 5px;

                        a {
                            background: $template_color;
                            color: $white-color;
                            padding: 5px 15px 3px;
                            display: inline-block;
                            border-radius: 3px;
                            font-weight: 400;
                        }
                    }
                }
            }
            h3 {
                margin-bottom: -5px;
                font: {
                    size: 23px;
                    weight: 700;
                }
            }
            p {
                margin-bottom: 0;
                margin-top: 20px;
            }
            .blockquote {
                background: #f4f4f4;
                padding: 30px;
                border-left: 4px solid $template_color;
                margin: {
                    bottom: 20px;
                    top: 20px;
                }
                p {
                    margin-top: 0;
                    font-size: 18px;
                }
            }
            .share-post {
                text-align: center;
                margin-top: 30px;

                ul {
                    li {
                        margin: {
                            left: 3px;
                            right: 3px;
                        };
                        a {
                            width: 40px;
                            font-size: 15px;
                            height: 40px;
                            display: inline-block;
                            line-height: 38px;
                            border: 1px solid #eeeeee;
                            border-radius: 50%;
                            color: #555555;

                            &:hover, &:focus {
                                color: $white-color;
                                border-color: $template_color;
                                background-color: $template_color;
                            }
                        }
                    }
                }
            }
            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;
            }
        }
    }
    .post-controls-buttons {
        overflow: hidden;
        background: $white-color;;
        padding: 30px;
        box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
        margin: {
            top: 25px;
            bottom: 25px;
        }
        .controls-left {
            float: left;
        }
        .controls-right {
            float: right;
        }
        div {
            a {
                border: 1px solid #eeeeee;
                padding: 8px 20px;
                display: inline-block;
                border-radius: 5px;
                font-weight: 700;

                &:hover, &:focus {
                    color: $white-color;
                    background: $template_color;
                    border-color: $template_color;
                }
            }
        }
    }
    .post-comments {
        padding: 30px;
        background: $white-color;
        box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);

        h3 {
            margin-bottom: 0;
            font: {
                size: 23px;
                weight: 700;
            }
        }
        .single-comment {
            position: relative;
            padding-left: 95px;
            margin-top: 30px;

            .comment-img {
                position: absolute;
                left: 0;
                top: 0;
                width: 75px;
                height: 75px;

                img {
                    border-radius: 50%;
                }
            }
            .comment-content {
                h4 {
                    margin-bottom: 10px;
                    text-transform: uppercase;
                    font: {
                        size: 15px;
                        weight: 700;
                    }
                }
                span {
                    color: $paragraph_color;
                }
                p {
                    margin-bottom: 15px;
                }
                a {
                    position: absolute;
                    font-weight: 700;
                    right: 0;
                    bottom: 0;
                    text-transform: uppercase;
                }
            }
            &.left-m {
                margin-left: 85px;
            }
        }
    }
    .leave-a-reply {
        padding: 30px;
        background: $white-color;
        box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
        margin-top: 25px;

        h3 {
            margin-bottom: 30px;
            font: {
                size: 23px;
                weight: 700;
            }
        }
    }
}

/*================================================
Boxes CSS
=================================================*/
.boxes-area {
    position: relative;
    z-index: 1;
    margin-top: -140px;

    .single-box {
        padding: 30px;
        background: $white_color;
        box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
        border-radius: 3px;
        transition: .4s;

        .icon {
            display: inline-block;
            width: 70px;
            height: 70px;
            line-height: 70px;
            background: #edf5ff;
            border-radius: 50%;
            text-align: center;
            font-size: 25px;
            color: $template_color;
            transition: .4s;
        }
        h3 {
            margin: 25px 0 15px;
            font: {
                size: 23px;
                weight: 700;
            }
        }
        &:hover {
            transform: translateY(-10px);

            .icon {
                color: $white_color;
                background: $template_color2;
            }
        }
    }
}

/*================================================
Join CSS
=================================================*/
.join-content {
    span {
        display: block;
        color: $template_color2;
        margin-bottom: 10px;
        text-transform: uppercase;
        font: {
            size: 14px;
        }
    }
    h3 {
        margin-bottom: 15px;
        font: {
            size: 23px;
            weight: 700;
        }
    }
    p {
        margin-bottom: 25px;
    }
}

/*================================================
Analysis CSS
=================================================*/
.analysis-form {
	background: $white_color;
	box-shadow: 5px 7px 30px rgba(72,73,121,0.15);
	padding: 40px;
	border-radius: 5px;
    text-align: center;

    h3 {
        margin-bottom: 30px;
        font: {
            size: 23px;
            weight: 700;
        }
    }
    form {
        .btn {
            display: block;
            width: 100%;
            padding: 13px 40px;
            border-radius: 5px;
        }
    }
}
.analysis-text {
    span {
        color: $template_color2;
        text-transform: capitalize;
    }
    h3 {
        margin: 15px 0 20px;
        line-height: 40px;
        font: {
            size: 35px;
            weight: 700;
        }
    }
    ul {
        padding: 0;
        margin: 20px 0 0;
        list-style-type: none;

        li {
            position: relative;
            color: $paragraph_color;
            margin-bottom: 15px;
            padding-left: 30px;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 6px;
                width: 17px;
                height: 17px;
                background: $template_color;
                clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

/*================================================
Partner CSS
=================================================*/
.partner-area {
    padding: 50px 0;
    border-top: 1px solid #edf5ff;
    border-bottom: 1px solid #edf5ff;

    .item {
        text-align: center;

        a {
            display: block;

            img {
                width: auto !important;
            }
        }
    }
    .owl-controls {
        .owl-nav {
            margin-top: 0;
        }
    }
    &.app-partner {
        padding: 20px 0 0;
        border: 0;
    }
    &.marketing-partner {
        border: {
            bottom: 1px dashed #edf5ff;
            top: 1px dashed #edf5ff;
        }
    }
    &.software-partner {
        border: {
            bottom: 1px dashed #edf5ff;
            top: none;
        }
    }
    &.startup-partner {
        border: {
            bottom: 1px dashed #edf5ff;
            top: none;
        }
    }
}

/*================================================
Subscribe CSS
=================================================*/
.subscribe-area {
    position: relative;
    z-index: 1;
    background: {
        /* image: url(assets/img/map.png);*/
         position: center center;
         size: cover;
         attachment: fixed;
         repeat: no-repeat;
     }
     &::before {
         content: '';
         position: absolute;
         z-index: -1;
         left: 0;
         top: 0;
         width: 100%;
         height: 100%;
         background: $template_color;
         opacity: .67;
     }
     .section-title {
         h2, p {
             color: $white_color;
         }
         .bar {
             background: $white_color;

             &::before {
                 background: $template_color;
             }
         }
     }
     .newsletter-form {
         max-width: 650px;
         margin: 0 auto;
         position: relative;
         border-radius: 5px;

         .form-control {
             height: 70px;
             background: rgba(255, 255, 255, 1);
             box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.1);
             border: none;
             border-radius: 5px;
             color: $paragraph_color;
             padding-left: 20px;

             &::placeholder {
                 color: $paragraph_color;
             }
         }
         .btn {
             position: absolute;
             right: 0;
             top: 0;
             height: 100%;

             &:hover, &:focus {
                 transform: translateY(0);
             }
         }
     }
 }
 .bubble-animate {
     position: absolute;
     left: 0;
     bottom: 0;
     width: 100%;
     height: 100%;
     overflow: hidden;
     z-index: -1;

     .circle {
         background: $template_color;
         bottom: 0;
         position: absolute;
         border-radius: 50%;

         &.small {
             width: 20px;
             height: 20px;
             opacity: 0.7;

             &.square1 {
                 left: 18%;
                 top: 100%;
                 animation-name: smallBubble;
                 animation-duration: 3s;
                 animation-iteration-count: infinite;
                 animation-delay: 1s;
                 animation-timing-function: ease-in;
             }
             &.square2 {
                 left: 36%;
                 top: 100%;
                 animation-name: smallBubble;
                 animation-duration: 4s;
                 animation-iteration-count: infinite;
                 animation-delay: 2s;
                 animation-timing-function: ease-in;
             }
             &.square3 {
                 left: 54%;
                 top: 100%;
                 animation-name: smallBubble;
                 animation-duration: 5s;
                 animation-iteration-count: infinite;
                 animation-delay: 1s;
                 animation-timing-function: ease-in;
             }
             &.square4 {
                 left: 72%;
                 top: 100%;
                 animation-name: smallBubble;
                 animation-duration: 6s;
                 animation-iteration-count: infinite;
                 animation-delay: 4s;
                 animation-timing-function: ease-in;
             }
             &.square5 {
                 left: 90%;
                 top: 100%;
                 animation-name: smallBubble;
                 animation-duration: 7s;
                 animation-delay: 5s;
                 animation-timing-function: ease-in;
             }
         }
         &.medium {
             width: 40px;
             height: 40px;
             opacity: 0.35;

             &.square1 {
                 left: 21%;
                 top: 100%;
                 animation-name: mediumBubble;
                 animation-duration: 5s;
                 animation-iteration-count: infinite;
                 animation-delay: 2s;
                 animation-timing-function: ease-in;
             }
             &.square2 {
                 left: 42%;
                 top: 100%;
                 animation-name: mediumBubble;
                 animation-duration: 8s;
                 animation-iteration-count: infinite;
                 animation-delay: 6s;
                 animation-timing-function: ease-in;
             }
             &.square3 {
                 left: 63%;
                 top: 100%;
                 animation-name: mediumBubble;
                 animation-duration: 12s;
                 animation-iteration-count: infinite;
                 animation-delay: 12s;
                 animation-timing-function: ease-in;
             }
             &.square4 {
                 left: 84%;
                 top: 100%;
                 animation-name: mediumBubble;
                 animation-duration: 4s;
                 animation-iteration-count: infinite;
                 animation-delay: 12s;
                 animation-timing-function: ease-in;
             }
             &.square5 {
                 left: 105%;
                 top: 100%;
                 animation-name: mediumBubble;
                 animation-duration: 18s;
                 animation-iteration-count: infinite;
                 animation-delay: 6s;
                 animation-timing-function: ease-in;
             }
         }
         &.large {
             width: 100px;
             height: 100px;
             opacity: 0.15;

             &.square1 {
                 left: 21%;
                 top: 100%;
                 animation-name: bigBubble;
                 animation-duration: 6s;
                 animation-iteration-count: infinite;
                 animation-delay: 6s;
                 animation-timing-function: ease-in;
             }
             &.square2 {
                 left: 42%;
                 top: 100%;
                 animation-name: bigBubble;
                 animation-duration: 6s;
                 animation-iteration-count: infinite;
                 animation-delay: 3s;
                 animation-timing-function: ease-in;
             }
             &.square3 {
                 left: 63%;
                 top: 100%;
                 animation-name: bigBubble;
                 animation-duration: 6s;
                 animation-iteration-count: infinite;
                 animation-delay: 13s;
                 animation-timing-function: ease-in;
             }
             &.square4 {
                 left: 84%;
                 top: 100%;
                 animation-name: bigBubble;
                 animation-duration: 6s;
                 animation-iteration-count: infinite;
                 animation-delay: 9s;
                 animation-timing-function: ease-in;
             }
             &.square5 {
                 left: 105%;
                 top: 100%;
                 animation-name: bigBubble;
                 animation-duration: 6s;
                 animation-iteration-count: infinite;
                 animation-delay: 13s;
                 animation-timing-function: ease-in;
             }
         }
     }
 }
 @-webkit-keyframes smallBubble {
     0% {
         top: 100%;
         margin-left: 10px;
     }
     25% {
         margin-left: -10px;
     }
     50% {
         margin-left: 10px;
     }
     75% {
         margin-left: -10px;
     }
     100% {
         top: 0%;
         opacity: 0;
         margin-left: 0px;
     }
 }
 @keyframes smallBubble {
     0% {
         top: 100%;
         margin-left: 10px;
     }
     25% {
         margin-left: -10px;
     }
     50% {
         margin-left: 10px;
     }

     75% {
         margin-left: -10px;
     }
     100% {
         top: 0%;
         opacity: 0;
         margin-left: 0px;
     }
 }
 @-webkit-keyframes mediumBubble {
     0% {
         top: 100%;
         margin-left: 15px;
     }
     25% {
         margin-left: -15px;
     }
     50% {
         margin-left: 15px;
     }
     75% {
         margin-left: -15px;
     }
     100% {
         top: 0%;
         opacity: 0;
         margin-left: 0px;
     }
 }
 @keyframes mediumBubble {
     0% {
         top: 100%;
         margin-left: 15px;
     }
     25% {
         margin-left: -15px;
     }
     50% {
         margin-left: 15px;
     }
     75% {
         margin-left: -15px;
     }
     100% {
         top: 0%;
         opacity: 0;
         margin-left: 0px;
     }
 }
 @-webkit-keyframes bigBubble {
     0% {
         top: 100%;
         margin-left: 20px;
     }
     25% {
         margin-left: -20px;
     }
     50% {
         margin-left: 20px;
     }
     75% {
         margin-left: -20px;
     }
     100% {
         top: 0%;
         opacity: 0;
         margin-left: 0px;
     }
 }
 @keyframes bigBubble {
     0% {
         top: 100%;
         margin-left: 20px;
     }
     25% {
         margin-left: -20px;
     }
     50% {
         margin-left: 20px;
     }
     75% {
         margin-left: -20px;
     }
     100% {
         top: 0%;
         opacity: 0;
         margin-left: 0px;
     }
 }

 /*================================================
 Product CSS
 =================================================*/
.single-product {
    box-shadow: 0 10px 50px 0 rgba(46,65,90,.1);
    margin-bottom: 30px;

    .product-img {
        position: relative;
        overflow: hidden;

        img {
            transform: scale(1);
            transition: all 300ms ease;
        }
        .add-to-cart-btn {
            display: block;
            position: absolute;
            left: 0;
            bottom: -45px;
            width: 100%;
            background: $black_color;
            color: $white_color;
            height: auto;
            letter-spacing: 2px;
            padding: 12px 20px;
            text-transform: uppercase;
            font: {
                weight: 700;
                size: 14px;
            }
            overflow: hidden;
            transition: .4s;

            i {
                font-size: 20px;
                float: right;
            }
        }
    }
    .product-content {
        padding: 30px;

        h3 {
            margin-bottom: 15px;
            font: {
                size: 23px;
                weight: 700;
            }
        }
        .row {
            h5 {
                text-align: left;
                margin-bottom: 0;
                font: {
                    size: 18px;
                    weight: 700;
                }
                span {
                    font-size: 14px;
                    text-decoration: line-through;
                    position: relative;
                    top: -1px;
                    color: $paragraph_color;
                }
            }
            ul {
                text-align: right;
                padding: 0;
                margin: 0;
                list-style-type: none;

                li {
                    display: inline-block;
                    color: #ffb708;
                }
            }
        }
    }
    &:hover {
        .product-img {
            img {
                transform: scale(1.09);
            }
            .add-to-cart-btn {
                bottom: 0;
            }
        }
    }
}
.product-slides {
    &.owl-theme {
        .owl-nav {
            margin: {
                top: 20px;
            }
            [class*=owl-] {
                color: $template_color2;
                font-size: 30px;
                margin: 0 3px;
                padding: 0;
                background: transparent;
                display: inline-block;
                cursor: pointer;
                border-radius: 0;
                transition: .4s;

                &:hover {
                    color: $template_color;
                }
            }
        }
        .owl-controls {
            line-height: .1;
        }
    }
}
.woocommerce-topbar {
    margin-bottom: 30px;
}

/*================================================
Page Title CSS
=================================================*/
.page-title-banner {
    position: relative;
    background: $template_color;
    text-align: center;
    z-index: 1;
    padding: {
        top: 200px;
        bottom: 120px;
    }
    h2 {
        margin: 0;
        color: $white_color;
        font: {
            size: 35px;
            weight: 700;
        }
    }
    .bubble-animate {
        .circle {
            background: $white_color;
        }
    }
}

/*=============================
Pagination CSS
===============================*/
.pagination-area {
    text-align: center;
    margin-top: 40px;

    ul {
        padding: 0;
        margin: 0;

        .page-item {
            .page-link {
                padding: 11px 20px;
                margin: 0 3px;
                color: #6f8ba4;
                background-color: $white_color;
                border: none;
                border-radius: 5px;
                font-size: 16px;
                box-shadow: 0 2px 10px 0 #d8dde6;

                &:hover, &:focus {
                    color: $white_color;
                    background-color: $template_color;
                    box-shadow: unset;
                }
            }
            &.active {
                .page-link {
                    color: $white_color;
                    background-color: $template_color;
                }
            }
        }
    }
}

/*================================================
Sidebar CSS
=================================================*/
.sidebar-area {
    .widget {
        margin-bottom: 40px;

        &.widget-search {
            form {
                position: relative;

                .form-control {
                    background: #edf5ff;
                    border-color: transparent;

                    &:focus {
                        border-color: $template_color;
                    }
                }
                button {
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 100%;
                    width: 55px;
                    border: none;
                    background: transparent;
                    cursor: pointer;
                    transition: .4s;

                    &:hover {
                        background: $template_color;
                        color: $white_color;
                    }
                }
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
        .widget-title {
            margin-bottom: 0;
            font: {
                weight: 700;
                size: 23px;
            }
        }
        .bar {
            height: 4px;
            width: 65px;
            background: $template_color;
            margin: 12px 0 30px;
            position: relative;
            border-radius: 5px;
            overflow: hidden;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 5px;
                background: $white_color;
                animation-duration: 2s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                animation-name: MOVE-BG;
            }
        }
        &.widget_product_categories {
            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;

                li {
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        color: $paragraph_color;
                        position: relative;
                        font: {
                            weight: 600;
                        }
                        &:hover {
                            color: $template_color;
                            padding-left: 17px;

                            i {
                                opacity: 1;
                                visibility: visible;
                                left: 0;
                            }
                        }
                        i {
                            position: absolute;
                            left: 8px;
                            top: 50%;
                            margin-top: -8px;
                            opacity: 0;
                            visibility: hidden;
                            transition: .4s;
                        }
                    }
                }
            }
        }
        &.widget_post_categories {
            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;

                li {
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        color: $paragraph_color;
                        position: relative;
                        font: {
                            weight: 600;
                        }
                        &:hover {
                            color: $template_color;
                            padding-left: 17px;

                            i {
                                opacity: 1;
                                visibility: visible;
                                left: 0;
                            }
                        }
                        i {
                            position: absolute;
                            left: 8px;
                            top: 50%;
                            margin-top: -8px;
                            opacity: 0;
                            visibility: hidden;
                            transition: .4s;
                        }
                    }
                }
            }
        }
        &.widget_tag_cloud {
            .tagcloud {
                a {
                    background: #f5f9ff;
                    color: #938da6;
                    padding: 6px 26px;
                    border-radius: 30px;
                    margin-bottom: 5px;
                    font-weight: 600;

                    &:hover {
                        color: $white_color;
                        background: $template_color;
                    }
                }
            }
        }
        &.widget_recent_posts {
            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;

                li {
                    position: relative;
                    overflow: hidden;
                    margin-bottom: 15px;
                    padding-left: 110px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    .recent-post-thumb {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100px;

                        a {
                            display: block;
                        }
                    }
                    .recent-post-content {
                        h3 {
                            line-height: 23px;
                            font: {
                                size: 16px;
                                weight: 700;
                            }
                        }
                        .date {
                            display: block;
                            margin-top: 3px;
                            font-size: 14px;
                            color: #828893;
                        }
                    }
                }
            }
        }
        &.widget_recent_products {
            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;

                li {
                    position: relative;
                    overflow: hidden;
                    margin-bottom: 15px;
                    padding-left: 110px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    .recent-products-thumb {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100px;

                        a {
                            display: block;
                        }
                    }
                    .recent-products-content {
                        h3 {
                            line-height: 23px;
                            font: {
                                size: 16px;
                                weight: 700;
                            }
                        }
                        .date {
                            display: block;
                            margin-top: 3px;
                            font-size: 14px;
                            color: #828893;
                        }
                        ul {
                            margin: -3px 0 -3px;

                            li {
                                display: inline-block;
                                padding: 0;
                                margin: 0;
                                line-height: 1;
                                color: #ffba00;
                                margin-right: -2px;
                            }
                        }
                    }
                }
            }
        }
        &.widget_text {
            ul {
                padding: 0;
                margin: 0 -3px;
                list-style-type: none;
                overflow: hidden;

                li {
                    float: left;
                    padding: 0 3px;
                    width: 33.3333%;
                    margin-bottom: 6px;
                }
            }
        }
    }
}

/*================================================
Login CSS
=================================================*/
.login-form {
    background: $white_color;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 40px;
    border-radius: 5px;

    h3 {
        margin-bottom: 8px;
        font: {
            size: 23px;
            weight: 700;
        }
    }
    p {
        margin-bottom: 30px;
    }
    form {
        .form-check {
            margin-top: 18px;

            .form-check-label {
                color: $paragraph_color;
                position: relative;
                top: -2px;
            }
        }
        .forgot-password {
            margin: {
                bottom: 20px;
                top: 18px;
            }
            a {
                color: $paragraph_color;

                &:hover {
                    color: $template_color;
                }
            }
        }
        .btn {
            margin: {
                bottom: 20px;
                top: 10px;
            }
        }
        span {
            display: block;
            color: $paragraph_color;

            a {
                color: $template_color2;
            }
        }
    }
}

/*================================================
Signup CSS
=================================================*/
.signup-form {
    background: $white_color;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 40px;
    border-radius: 5px;

    h3 {
        margin-bottom: 25px;
        font: {
            size: 23px;
            weight: 700;
        }
    }
    form {
        .form-check {
            margin-top: 18px;

            .form-check-label {
                color: $paragraph_color;
                position: relative;
                top: -2px;
            }
        }
        .btn {
            margin: {
                bottom: 20px;
                top: 20px;
            }
        }
        span {
            display: block;
            color: $paragraph_color;

            a {
                color: $template_color2;
            }
        }
    }
}

/*================================================
FAQ CSS
=================================================*/
.faq-accordion {
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 60px;
    background: $white_color;
    border-radius: 10px;

    .accordion {
        list-style-type: none;
        padding: 0;
        margin: 0;

        .accordion-item {
            display: block;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
            .accordion-title {
                display: block;
                padding: 20px 30px;
                text-decoration: none;
                background: #f5f9ff;
                font: {
                    size: 20px;
                    weight: 700;
                }
                &:hover, &.active, &:focus {
                    background: $template_color;
                    color: $white_color;
                }
                &.active {
                    border-radius: 0;
                }
            }
            .accordion-content {
                display: none;
                position: relative;
                overflow: hidden;
                padding: 15px 15px 0;
                line-height: 1.9;

                &.show {
                    display: block;
                }
            }
        }
    }
}
.faq-contact {
    margin-top: 45px;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 60px;
    border-radius: 4px;

    h3 {
        margin-bottom: 25px;
        font: {
            size: 35px;
            weight: 700;
        }
    }
    form {
        .btn {
            margin-top: 15px;
        }
    }
}

/*================================================
Error 404 CSS
=================================================*/
.notfound {
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    background: $white_color;
    padding: 40px;
    padding-left: 280px;
    max-width: 820px;
    margin: 0 auto;
    z-index: 1;
    position: relative;
    border-radius: 50px 5px 5px 50px;

    .notfound-404 {
        position: absolute;
        left: 0;
        top: 50%;
        display: inline-block;
        width: 140px;
        height: 140px;
        margin-top: -68px;
        background: {
            image: url(https://datenpfleger.ch/assets/datenpfleger/img/emoji.png);
             size: cover;
         }
         &::before {
             content: '';
             position: absolute;
             width: 100%;
             height: 100%;
             transform: scale(2.4);
             border-radius: 50%;
             background-color: #f2f5f8;
             z-index: -1;
         }
     }
     h2 {
         margin-bottom: 10px;
         font: {
             size: 60px;
             weight: 700;
         }
     }
     span {
         display: block;
         margin-bottom: 20px;
         color: $paragraph_color;
         font-size: 18px;
     }
 }

 /*================================================
 Coming Soon CSS
 =================================================*/
.coming-soon {
    position: relative;
    z-index: 1;
    /* background-image: url(assets/img/book-banner.jpg);*/
     background-position: center center;
     background-size: cover;
     background-attachment: fixed;
     background-repeat: no-repeat;
     height: 100vh;
     text-align: center;

     &::before {
         z-index: -1;
         width: 100%;
         height: 100%;
         left: 0;
         top: 0;
         background: $black_color;
         opacity: .77;
         content: '';
         position: absolute;
     }
     .coming-soon-content {
         max-width: 650px;
         margin: 0 auto;

         h1 {
             color: $white_color;
             text-transform: uppercase;
             margin-bottom: 0;
             line-height: 55px;
             font: {
                 size: 45px;
                 weight: 900;
             }
         }
         p {
             color: #c7c3c7;
             margin: {
                 top: 20px;
                 bottom: 35px;
             }
         }
         form {
             position: relative;

             .form-control {
                 background: transparent;
                 border: 1px solid $white_color;
                 border-radius: 50px;
                 color: $white_color;
                 height: 55px;

                 &:focus {
                     border-color: $template_color;
                 }
             }
             button {
                 position: absolute;
                 right: 0;
                 height: 100%;
                 background: $template_color;
                 top: 0;
                 color: $white_color;
                 border: none;
                 width: 130px;
                 cursor: pointer;
                 text-transform: uppercase;
                 font-weight: 700;
                 border-radius: 0 50px 50px 0;
                 transition: .4s;

                 &:hover {
                     background: $template_color2;
                 }
             }
         }
         #timer {
             margin-bottom: 35px;

             div {
                 display: inline-block;
                 color: $white_color;
                 width: 135px;
                 height: 135px;
                 border: 1px solid #eeeeee;
                 border-radius: 50%;
                 padding-top: 34px;
                 line-height: 40px;
                 margin: 0 5px;
                 font: {
                     size: 45px;
                     weight: 900;
                 }
             }
             span {
                 display: block;
                 font: {
                     size: 17px;
                     weight: 600;
                 }
             }
         }
     }
 }

 /*================================================
 Contact Us CSS
 =================================================*/
.contact-info-box {
    text-align: center;
    transition: .4s;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    background: $white_color;
    padding: 30px;

    .icon {
        display: inline-block;
        width: 80px;
        height: 80px;
        line-height: 80px;
        background: #edf5ff;
        border-radius: 50%;
        font-size: 30px;
        color: $template_color;
        transition: .4s;
    }
    h3 {
        margin: 25px 0 15px;
        font: {
            size: 23px;
            weight: 700;
        }
    }
    p {
        margin-bottom: 0;

        a {
            color: $paragraph_color;

            &:hover {
                color: $template_color;
            }
        }
    }
    &:hover {
        transform: translateY(-10px);

        .icon {
            color: $white_color;
            background: $template_color2;
        }
    }
}
#map {
    height: 500px;
    width: 100%;
}
#contactForm {
    .btn {
        margin-top: 10px;
    }
}

/*================================================
Footer CSS
=================================================*/
.footer-area {
    position: relative;
    z-index: 1;

    .single-footer-widget {
        .logo {
            display: block;
            margin-bottom: 25px;

            img {
                width: 150px;
                height: auto;
            }
        }
        p {
            margin-bottom: 0;
        }
        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            &.social-list {
                margin-top: 20px;

                li {
                    display: inline-block;
                    margin-right: 10px;

                    a {
                        color: $black_color;

                        &:hover {
                            color: $template_color;
                        }
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            &.list {
                li {
                    margin-bottom: 9px;

                    a {
                        display: block;
                        color: $paragraph_color;
                        font-weight: 600;

                        &:hover {
                            color: $template_color;
                            padding-left: 7px;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        h3 {
            font: {
                size: 23px;
                weight: 700;
            }
            margin-bottom: 25px;
        }
    }
    &.bg-image {
        background: {
            /* image: url(https://datenpfleger.ch/assets/img/footer-shape.png);*/
             position: center;
             size: cover;
             repeat: no-repeat;
         }
     }
 }
 .copyright-area {
     padding: 30px 0;
     margin-top: 100px;
     border-top: 1px solid #eeeeee;

     ul {
         text-align: right;
         list-style-type: none;
         padding: 0;
         margin: 0;

         li {
             display: inline-block;
             position: relative;
             padding-left: 20px;

             &::before {
                 content: '';
                 position: absolute;
                 left: 9px;
                 top: 5px;
                 background: $template_color2;
                 width: 2px;
                 height: 12px;
             }
             &:first-child {
                 &::before {
                     display: none;
                 }
             }
             a {
                 color: $paragraph_color;

                 &:hover {
                     color: $template_color;
                 }
             }
         }
     }
 }

 /*================================================
 Back To Top CSS
 =================================================*/
.back-to-top {
    position: fixed;
    right: 1.5%;
    bottom: -7.5%;
    height: 15px;
    cursor: pointer;
    width: 40px;
    padding: 3px 5px;
    color: transparent;
    text-align: center;
    z-index: 3;
    visibility: hidden;
    outline: 0 !important;
    transition: all 0.25s ease-in-out;
    background-color: $paragraph_color;
    font: {
        size: 10px;
        weight: bold;
    }
    &::before, &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 0;
        height: 0;
        border: 20px solid transparent;
        border-top: 0;
    }
    &::before {
        top: -20px;
        z-index: 4;
        border-bottom: 20px solid $paragraph_color;
    }
    &::after {
        bottom: 0;
        z-index: 5;
        border-bottom: 20px solid $template_color;
    }
    &:hover, &:focus {
        opacity: 1;
        text-decoration: none;
        height: 40px;
        color: $white_color;
    }
    &.show-back-to-top {
        display: block;
        bottom: 1.25%;
        visibility: visible;
    }
}
